/* global BigInt */
import intToHexString from 'utils/intToHexString';
import {
  embed,
  compose_token_command as composeTokenCommand,
} from '@coinweb/cweb-wallet-library';

export default async function calculateSentEmbed(
  sellPrice,
  buyPrice,
  buyToken,
  sellToken,
  caclutateType,
  slipPage,
  wallet,
) {
  try {
    if (
      !slipPage ||
      buyPrice === 0 ||
      sellPrice === 0 ||
      (buyToken === undefined && sellToken === undefined)
    )
      return ``;
    let jsonQr;
    if (caclutateType === 'sell' && slipPage) {
      if (buyToken === undefined) {
        jsonQr = await composeTokenCommand(wallet, {
          BuyViaCwebUi: {
            token: sellToken,
            cweb_amount: intToHexString(sellPrice, 0),
            limit_token_amount: intToHexString(buyPrice * (1 - slipPage), 0),
          },
        });
      }
      if (sellToken === undefined) {
        jsonQr = await composeTokenCommand(wallet, {
          SellTokenUi: {
            token: buyToken,
            token_amount: intToHexString(sellPrice, 0),
            limit_cweb_amount: intToHexString(buyPrice * (1 - slipPage), 0),
          },
        });
      }
      if (sellToken && buyToken) {
        jsonQr = await composeTokenCommand(wallet, {
          SellForTokenUi: {
            token_a: buyToken,
            token_b: sellToken,
            token_a_amount: intToHexString(sellPrice, 0),
            limit_token_b_amount: intToHexString(buyPrice * (1 - slipPage), 0),
          },
        });
      }
    } else if (slipPage) {
      if (buyToken === undefined) {
        jsonQr = await composeTokenCommand(wallet, {
          BuyTokenUi: {
            token: sellToken,
            token_amount: intToHexString(buyPrice, 0),
            limit_cweb_amount: intToHexString(sellPrice * (1 + slipPage), 0),
          },
        });
      }
      if (sellToken === undefined) {
        jsonQr = await composeTokenCommand(wallet, {
          SellViaCwebUi: {
            token: buyToken,
            cweb_amount: intToHexString(buyPrice, 0),
            limit_token_amount: intToHexString(sellPrice * (1 + slipPage), 0),
          },
        });
      }
      if (sellToken && buyToken) {
        jsonQr = await composeTokenCommand(wallet, {
          BuyForTokenUi: {
            token_a: buyToken,
            token_b: sellToken,
            token_b_amount: intToHexString(buyPrice, 0),
            limit_token_b_amount: intToHexString(sellPrice * (1 + slipPage), 0),
          },
        });
      }
    }
    const uuid = await embed(wallet, jsonQr.l2_transaction);
    return uuid;
  } catch {
    return '';
  }
}
