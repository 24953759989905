import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Token from './pages/Token/Token';
import Exchange from './pages/Exchange/Exchange';
import TokenAbout from './pages/TokenAbout/TokenAbout';
import { Login } from './pages/Login/Login';
import './App.css';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/:pubKey" element={<Layout />}>
          <Route index element={<Exchange />} />
          <Route path="token" element={<Token />} />
          <Route path="token/:id" element={<TokenAbout />} />
        </Route>
        <Route path="*" element={<Login />} />
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
