import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import NiceModal from '@ebay/nice-modal-react';
import {
  ApolloClient,
  ApolloProvider,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import 'styles/index.scss';
import { theme } from 'theme';
import { REACT_APP_API_ENDPOINT } from './conf';
import App from './App';
import store from './redux/store';

const a = '/wallet';

const httpLink = new HttpLink({
  uri: REACT_APP_API_ENDPOINT + a,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  // wsLink,
  httpLink,
  httpLink,
);

// eslint-disable-next-line
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    resultCacheMaxSize: 5000000,
    typePolicies: {
      Block: {
        keyFields: ['hash'],
      },
      Transaction: {
        keyFields: ['txid'],
      },
    },
  }),
});

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <NiceModal.Provider>
                <App />
              </NiceModal.Provider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </ApolloProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
