import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { usePod } from 'hooks/usePod';
import iconCross from 'assets/img/cross.svg';
import iconTransaction from 'assets/img/transaction.png';
import iconCopy from 'assets/img/copy.png';
import iconAvatar from 'assets/img/avatar1.svg';
import iconCoin from 'assets/img/logo_coinweb.svg';
import styles from './ModalLogin.module.css';
import { Props } from '../ModalCancelled/ModalCancelled';
import { LoginQrScanner } from '../../LoginQrScanner/LoginQrScanner';
import {
  fieldIsLogin,
  fieldLogOut,
} from '../../../redux/reducers/tokenReducer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: {
    xs: 350,
    sm: 450,
    md: 450,
    lg: 450,
    xl: 450,
  },
  bgcolor: 'var(--background-color)',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--background-color)',
  p: '16px',
};

const ModalLogin = (props: Props) => {
  const [subscription] = usePod();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    props.handleClose();
    subscription.subscribe().unsubscribe();
    localStorage.removeItem('privKey');
    dispatch(fieldIsLogin(false));
    dispatch(fieldLogOut());
    navigate('/');
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          {props.activeLogin === false ? (
            <>
              <div className={styles.header}>
                <h4>Connect Wallet</h4>
                <div onClick={props.handleClose}>
                  <img src={iconCross} alt="" />
                </div>
              </div>
              <div className={styles.admissible}>
                <LoginQrScanner onScan={props.onChange} />
              </div>
              <div className={styles.footer}>
                <div className={styles.footerBlock}>
                  <img src={iconCoin} alt="" />
                  <p>| WALLET</p>
                </div>
              </div>
              <div className={styles.validityComment}>
                <div className={styles.validityCommentBlock}>
                  <p>
                    <img src={iconCopy} alt="" />
                    Copy to Clipboard
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={styles.header}>
                <h4>Account</h4>
                <div onClick={props.handleClose}>
                  <img style={{ cursor: 'pointer' }} src={iconCross} alt="" />
                </div>
              </div>
              <div className={styles.loginWindows}>
                <div className={styles.validity}>
                  <div className={styles.validityBlockSmall}>
                    <p>Connected with</p>
                    <img className={styles.logoImg} src={iconCoin} alt="" />
                    <span className={styles.textSpam}>| WALLET</span>
                  </div>
                </div>
                <div className={styles.validity}>
                  <div className={styles.validityBlock}>
                    <div id={styles.avatarImgContainer}>
                      <img src={iconAvatar} alt="" id={styles.avatarImg} />
                    </div>{' '}
                    <p>{props.token}</p>
                    <div onClick={logOut}>Logoff</div>
                  </div>
                </div>
                <div className={styles.validityComment}>
                  <div
                    className={styles.validityCommentBlockLogin}
                    onClick={() =>
                      navigator.clipboard.writeText(props.token ?? '')
                    }
                  >
                    <img src={iconCopy} alt="" />
                    <p>Copy Address</p>
                  </div>
                </div>
                <hr className={styles.validityHr} />
                <div className={styles.validity}>
                  <div
                    className={styles.validityBlockSmall}
                    onClick={props.handleClose}
                  >
                    <p>Transactions</p>
                  </div>
                </div>
                <div className={styles.validityComment}>
                  <div className={styles.validityCommentBlockLogin}>
                    <img src={iconTransaction} alt="" />
                    <p>View in Coinweb Explorer</p>
                  </div>
                </div>
              </div>
              {/* <div className={styles.validityComment}>
                <div className={styles.validityCommentBlockLeft}>
                  <p>Your transactions will appear here ...</p>
                </div>
              </div> */}
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalLogin;
