import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import round from 'utils/round';
import formatNumber from 'utils/formatNumber';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fieldSlipPage } from '../../redux/reducers/tokenReducer';
import styles from './Accordion.module.css';
import iconInfo from '../../assets/img/attetion.svg';

type SwapAccordionProps = {
  onChangeSwapDisable: React.Dispatch<React.SetStateAction<boolean>>;
};
const SwapAccordion = (props: SwapAccordionProps) => {
  const dispatch = useDispatch();

  const {
    fieldTokenBuy,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    slipPage,
    networkFee,
    priceImpact,
    unitTokenPrice,
  } = useSelector((state: any) => state.tokenReducer);

  const [slippage, setSlippage] = useState<string>((slipPage * 100).toString());

  const [slippageError, setSlippageError] = useState<boolean>(false);

  const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!value || value === '') {
      setSlippage('');
      return;
    }
    try {
      const numberValue = parseFloat(value);
      if ((numberValue === 0 || numberValue >= 0.5) && numberValue <= 20) {
        setSlippage(value);
        dispatch(fieldSlipPage(numberValue / 100));
      }
    } catch (error) {
      console.error('Error parsing input value:', error);
    }
  };

  useEffect(() => {
    if (slippage) {
      const numberValue = parseFloat(slippage);

      if (numberValue < 0.5 || numberValue > 20) {
        props.onChangeSwapDisable(true);
        setSlippageError(true);
      } else {
        props.onChangeSwapDisable(false);
        setSlippageError(false);
      }
    }
  }, [slippage]);
  return (
    <div>
      <Accordion className={styles.accordionBoard}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={styles.validityName}>
            <div className={styles.validityBlockName}>
              <img src={iconInfo} alt="" />
              <p>
                1 {fieldTokenSellChoose.name} = {unitTokenPrice}{' '}
                {fieldTokenBuyChoose.name}
              </p>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div className={styles.loginWindows}>
              <div className={styles.validity}>
                <div className={styles.validityBlock1}>
                  <p>Expected Output</p>
                  <div>
                    {Number(round(Number(fieldTokenBuy) * 1))}{' '}
                    {fieldTokenBuyChoose.name}
                  </div>
                </div>
              </div>
              <div className={styles.validity}>
                <div className={styles.validityBlock2}>
                  <p>Price impact</p>
                  <div>{formatNumber(Number(round(Number(priceImpact))))}%</div>
                </div>
              </div>
              <hr className={styles.validityHr} />
              <div className={styles.validity}>
                <div className={styles.validityBlock3}>
                  <p>
                    Maximum received after slippage (
                    {Number(round(slipPage * 100))}%)
                  </p>
                  <div>
                    {Number(round(Number(fieldTokenBuy) * (1.0 + slipPage)))}{' '}
                    {fieldTokenBuyChoose.name}
                  </div>
                </div>
              </div>
              <div className={styles.validity}>
                <div className={styles.validityBlock3}>
                  <p>
                    Minimum received after slippage (
                    {Number(round(slipPage * 100))}%)
                  </p>
                  <div>
                    {Number(round(Number(fieldTokenBuy) * (1.0 - slipPage)))}{' '}
                    {fieldTokenBuyChoose.name}
                  </div>
                </div>
              </div>
              <div className={styles.validity}>
                <div className={styles.validityBlock4}>
                  <p>Network Fee</p>
                  <div>~{Number(round(Number(networkFee)))}%</div>
                </div>
              </div>
            </div>
            <div className={styles.changeSlippage}>
              You can change the slippage in the range of 0.5-20%{' '}
              <input
                min="0"
                pattern="^[0-9]*[.,]?[0-9]*$"
                type="number"
                className={styles.input}
                value={slippage || ''}
                onChange={handlerInput}
                placeholder="5"
              />
              {slippageError && (
                <p id={styles.errorText}>
                  Slip page value should be between 0.5 and 20.
                </p>
              )}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default SwapAccordion;
