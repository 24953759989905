import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wallet } from '@coinweb/cweb-wallet-library';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import calculatePrice from 'utils/calculatePrice';
import fullUrl from 'utils/fullUrl';
import handleErrorMessage from 'utils/handleErrorMessage';
import logoDefault from 'assets/img/logoDefault.png';
import styles from './ModalToken.module.css';
import {
  fieldPriceType,
  fieldSwapResult,
  fieldPriceImpact,
  fieldNetworkFee,
  fieldIpfsIcon,
  fieldSwapResultErrorMessage,
} from '../../../redux/reducers/tokenReducer';
import iconCross from '../../../assets/img/cross.svg';

type Props = {
  calType: 'sell' | 'buy';
  wallet: Wallet | undefined;
  open: boolean;
  handleClose: () => void;
  onChange: (fieldTokenBuyChoose: any) => {
    type: string;
    fieldTokenBuyChoose: any;
  };
  onValueAddressChange: any;
  onValueTokenChange: any;
  selected: any;
  headersList1: any;
  headersList2: any;
  headersList3: any;
  tokens1: any;
  tokens2: any;
  tokens3: any;
  balance1: any;
  balance2: any;
  balance3: any;
  balanceCweb: any;
};
const constHashSTBL =
  '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';

const constNameSTBL = 'Stability';
const constSymbolSTBL = 'STBL';
const constExponentSTBL = 18;

const constImageSTBL =
  'bafkreier7mirvue5ae4n65uzbldniziaxsligaxfgr62oyoulygpbr2n2y';

const constMasSTBL = [
  {
    name: 'ipfs_image_url',
    content: constImageSTBL,
  },
  {
    name: 'symbol',
    content: constSymbolSTBL,
  },
  {
    name: 'name',
    content: constNameSTBL,
  },
  {
    name: 'exponent',
    content: constExponentSTBL,
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '14px',
  width: {
    xs: 350,
    sm: 450,
    md: 450,
    lg: 450,
    xl: 450,
  },
  bgcolor: 'background.paper',
  border: 'solid 1px var(--background-color-2)',
  boxShadow: ' 0 8px 25px 0 var(--main-box-shadow-color)',
  color: 'var(--main-white-color)',
  p: '16px 0',
  backgroundColor: 'var(--background-color-modal-2)',
};
type TokenBodyProps = {
  info: any;
  selected: any;
  hashId: any;
  calType: 'buy' | 'sell';
};
const TokenBody = (props: TokenBodyProps) => {
  const { ipfsIcon, fieldTokenSellChoose } = useSelector(
    (state: any) => state.tokenReducer,
  );

  const dispatch = useDispatch();

  const [img, setImg] = useState('');
  const [name, setName] = useState('');
  const [text, setText] = useState('');
  useEffect(() => {
    (async () => {
      await props.info.forEach(async (element: any) => {
        if (element.name === 'ipfs_image_url' && img === '') {
          let imgNew = '';
          const findIcon = ipfsIcon.find(
            (el: any) => el.hash === element.content,
          );
          if (findIcon) {
            imgNew = findIcon.icon;
          } else {
            imgNew = await fullUrl(element.content);
            if (imgNew === '') imgNew = logoDefault;
            ipfsIcon.push({
              hash: element.content,
              icon: imgNew,
            });
            dispatch(fieldIpfsIcon(ipfsIcon));
          }
          setImg(imgNew);
        }
        if (element.name === 'symbol') setName(element.content);
        if (element.name === 'name') setText(element.content);
      });
    })();
  }, []);
  return (
    <>
      {img !== '' ? (
        <img
          className={`${styles.headersblockListImg} ${
            fieldTokenSellChoose?.hash === props.hashId &&
            props.calType !== 'buy' &&
            styles.disableImg
          }`}
          src={img}
          alt=""
        />
      ) : (
        <CircularProgress size={25} />
      )}
      <div>
        <div className={styles.headersblockListItemText}>
          <p>{name}</p>
        </div>
        <p className={styles.headersblockListItemName}> {text} </p>
      </div>
      {typeof props.hashId !== 'string' ? (
        <div className={styles.hashStyle}> {props.hashId.token} </div>
      ) : (
        <div className={styles.hashStyle}> {props.hashId} </div>
      )}
      {props.selected === 1 && (
        <CheckIcon
          sx={{ color: ' var( --check-icon-color)', marginLeft: 'auto' }}
        />
      )}
    </>
  );
};

const ModalToken = (props: Props) => {
  const dispatch = useDispatch();

  const {
    fieldTokenSell,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    tokenSweb,
  } = useSelector((state: any) => state.tokenReducer);

  const activeItemHandler = async (
    id: number,
    item: any,
    balance: any,
    hash: any | undefined = undefined,
    sweb: any = undefined,
  ) => {
    props.handleClose();

    if (hash !== undefined && typeof hash !== 'string') hash = hash.token;
    let exponent = 18;
    if (sweb) {
      if (
        fieldTokenSellChoose.name === sweb.text ||
        fieldTokenBuyChoose.name === sweb.text
      )
        return;
      exponent = sweb.exponent;
      dispatch(
        props.onChange({
          id,
          balance,
          hash: sweb.id,
          name: sweb.text,
          img: sweb.img,
          exponent: sweb.exponent,
        }),
      );
    } else {
      let img = '';
      let name = '';
      if (hash === constHashSTBL) {
        constMasSTBL.forEach((element: any) => {
          if (element.name === 'ipfs_image_url') img = element.content;
          if (element.name === 'symbol') name = element.content;
          if (element.name === 'exponent') exponent = Number(element.content);
        });
      } else {
        item.TokenQueryResult.LastExtraFields.Ok.forEach((element: any) => {
          if (element.name === 'ipfs_image_url') img = element.content;
          if (element.name === 'symbol') name = element.content;
          if (element.name === 'exponent') exponent = Number(element.content);
        });
      }
      if (
        fieldTokenSellChoose.name === name ||
        fieldTokenBuyChoose.name === name
      )
        return;
      dispatch(props.onValueAddressChange(0));
      dispatch(props.onValueTokenChange(0));
      dispatch(
        props.onChange({
          id,
          balance,
          hash,
          name,
          img: '',
          imgH: img,
          exponent,
        }),
      );
    }
    let price: any = {
      networkFee: '0',
      priceImpact: '0',
      tokenAmount: '0',
      err: undefined,
    };
    try {
      if (props.calType === 'buy') {
        if (fieldTokenBuyChoose.id === undefined) return;
        price = await calculatePrice(
          props.wallet,
          fieldTokenSell * 10 ** exponent,
          hash,
          fieldTokenBuyChoose.hash,
          'sell',
          fieldTokenBuyChoose.exponent,
        );
      } else {
        if (fieldTokenSellChoose.id === undefined) return;
        price = await calculatePrice(
          props.wallet,
          fieldTokenSell * 10 ** fieldTokenSellChoose.exponent,
          fieldTokenSellChoose.hash,
          hash,
          'sell',
          exponent,
        );
      }
      if (price.tokenAmount > 0) dispatch(fieldSwapResult(true));
      else {
        dispatch(fieldSwapResultErrorMessage(handleErrorMessage(price.err)));
        dispatch(fieldSwapResult(false));
      }
      dispatch(fieldPriceImpact(price.priceImpact));
      dispatch(fieldNetworkFee(price.networkFee));
    } catch {
      dispatch(fieldSwapResult(false));
      dispatch(
        fieldSwapResultErrorMessage('Connection error. Please try again later'),
      );
      dispatch(fieldPriceImpact(price.priceImpact));
      dispatch(fieldNetworkFee(price.networkFee));
    }
    dispatch(fieldPriceType('sell'));
  };

  const hashList1 = props.tokens1;
  const hashList2 = props.tokens2;
  const hashList3 = props.tokens3;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.headerBody}>
            <div className={styles.header}>
              <p style={{ fontSize: '16px', paddingBottom: '10px' }}>
                Select token
              </p>
              <div onClick={props.handleClose}>
                <img src={iconCross} alt="" />
              </div>
            </div>
          </div>
          <div className={styles.headersblockList}>
            <div
              key={0}
              onClick={() => {
                if (
                  fieldTokenSellChoose?.name === 'CWEB' &&
                  props.calType !== 'buy'
                )
                  return;
                activeItemHandler(
                  0,
                  '',
                  props.balanceCweb,
                  undefined,
                  tokenSweb,
                );
              }}
              className={`${styles.headersblockListItem} ${
                fieldTokenSellChoose?.name === 'CWEB' &&
                props.calType !== 'buy' &&
                styles.disable
              }`}
            >
              <img
                className={`${styles.headersblockListImg} ${
                  fieldTokenSellChoose?.name === 'CWEB' &&
                  props.calType !== 'buy' &&
                  styles.disableImg
                }`}
                src={tokenSweb.img}
                alt=""
              />
              <div>
                <div className={styles.headersblockListItemText}>
                  <p>{tokenSweb.text}</p>
                </div>
                <p className={styles.headersblockListItemName}>
                  {tokenSweb.name}
                </p>
              </div>
              {props.selected.name === 'CWEB' && (
                <CheckIcon
                  sx={{
                    color: ' var( --check-icon-color)',
                    marginLeft: 'auto',
                  }}
                />
              )}
            </div>
            {(props.headersList1.length > 0 || props.headersList2.length > 0) &&
              props.calType !== 'buy' && <div>Held and subscribed</div>}
            {props.headersList1.map((item: any, index: number) => {
              if (
                item.TokenQueryResult.LastExtraFields.Err === undefined &&
                item.TokenQueryResult.LastExtraFields.Ok.length > 0
              )
                return (
                  <div
                    key={index + 1} // eslint-disable-line
                    onClick={() => {
                      if (
                        fieldTokenSellChoose?.hash === hashList1[index] &&
                        props.calType !== 'buy'
                      )
                        return;
                      activeItemHandler(
                        index,
                        item,
                        props.balance1[index],
                        hashList1[index],
                      );
                    }}
                    className={`${styles.headersblockListItem} ${
                      fieldTokenSellChoose?.hash === hashList1[index] &&
                      props.calType !== 'buy' &&
                      styles.disable
                    }`}
                  >
                    {props.selected.hash === hashList1[index] ? (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList1[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList1[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              if (hashList1[index] === constHashSTBL) {
                return (
                  <div
                    key={index + 1} // eslint-disable-line
                    onClick={() => {
                      if (
                        fieldTokenSellChoose?.hash === hashList1[index] &&
                        props.calType !== 'buy'
                      )
                        return;
                      activeItemHandler(
                        index,
                        item,
                        props.balance1[index],
                        hashList1[index],
                      );
                    }}
                    className={`${styles.headersblockListItem} ${
                      fieldTokenSellChoose?.hash === hashList1[index] &&
                      props.calType !== 'buy' &&
                      styles.disable
                    }`}
                  >
                    {props.selected.hash === hashList1[index] ? (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList1[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList1[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              }
              return (
                <div
                  key={item.id}
                  onClick={() =>
                    activeItemHandler(
                      index,
                      item,
                      props.balance1[index],
                      hashList1[index],
                    )
                  }
                  className={styles.headersblockListItem}
                >
                  <p>Name Test {index}</p>
                  {props.selected.hash === hashList1[index] && (
                    <CheckIcon
                      sx={{
                        color: ' var( --check-icon-color)',
                        marginLeft: 'auto',
                      }}
                    />
                  )}
                </div>
              );
            })}
            {props.headersList2.map((item: any, index: number) => {
              if (
                item.TokenQueryResult.LastExtraFields.Err === undefined &&
                item.TokenQueryResult.LastExtraFields.Ok.length > 0
              )
                return (
                  <div
                    key={props.headersList1.length + index + 1} // eslint-disable-line
                    onClick={() =>
                      activeItemHandler(
                        index,
                        item,
                        props.balance2[index],
                        hashList2[index],
                      )
                    }
                    className={styles.headersblockListItem}
                  >
                    {props.selected.hash === hashList2[index] ? (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList2[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList2[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              if (hashList2[index] === constHashSTBL) {
                return (
                  <div
                    key={props.headersList1.length + index + 1} // eslint-disable-line
                    onClick={() =>
                      activeItemHandler(
                        index,
                        item,
                        props.balance2[index],
                        hashList2[index],
                      )
                    }
                    className={styles.headersblockListItem}
                  >
                    {props.selected.hash === hashList2[index] ? (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList2[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList2[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              }
              return (
                <div
                  key={item.id}
                  onClick={() =>
                    activeItemHandler(
                      index,
                      item,
                      props.balance2[index],
                      hashList2[index],
                    )
                  }
                  className={styles.headersblockListItem}
                >
                  <p>Name Test {index}</p>
                  {props.selected.hash === hashList2[index] && (
                    <CheckIcon
                      sx={{
                        color: ' var( --check-icon-color)',
                        marginLeft: 'auto',
                      }}
                    />
                  )}
                </div>
              );
            })}
            {props.headersList3.length > 0 && props.calType !== 'buy' && (
              <div>Other whitelisted</div>
            )}
            {props.headersList3.map((item: any, index: number) => {
              if (
                item.TokenQueryResult.LastExtraFields.Err === undefined &&
                item.TokenQueryResult.LastExtraFields.Ok.length > 0
              )
                return (
                  <div
                    key={
                      props.headersList1.length + // eslint-disable-line
                      props.headersList2.length + // eslint-disable-line
                      index + // eslint-disable-line
                      1
                    }
                    onClick={() =>
                      activeItemHandler(
                        index,
                        item,
                        props.balance3[index],
                        hashList3[index],
                      )
                    }
                    className={styles.headersblockListItem}
                  >
                    {props.selected.hash === hashList3[index] ? (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList3[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={item.TokenQueryResult.LastExtraFields.Ok}
                        hashId={hashList3[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              if (hashList3[index] === constHashSTBL) {
                return (
                  <div
                    key={
                      props.headersList1.length + // eslint-disable-line
                      props.headersList2.length + // eslint-disable-line
                      index + // eslint-disable-line
                      1
                    }
                    onClick={() =>
                      activeItemHandler(
                        index,
                        item,
                        props.balance3[index],
                        hashList3[index],
                      )
                    }
                    className={styles.headersblockListItem}
                  >
                    {props.selected.hash === hashList3[index] ? (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList3[index]}
                        selected={1}
                        calType={props.calType}
                      />
                    ) : (
                      <TokenBody
                        info={constMasSTBL}
                        hashId={hashList3[index]}
                        selected={0}
                        calType={props.calType}
                      />
                    )}
                  </div>
                );
              }
              return (
                <div
                  key={item.id}
                  onClick={() =>
                    activeItemHandler(
                      index,
                      item,
                      props.balance3[index],
                      hashList3[index],
                    )
                  }
                  className={styles.headersblockListItem}
                >
                  <p>Name Test {index}</p>
                  {props.selected.hash === hashList3[index] && (
                    <CheckIcon
                      sx={{
                        color: ' var( --check-icon-color)',
                        marginLeft: 'auto',
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalToken;
