import Typography from '@mui/material/Typography';
import Logo from 'images/logo_login.svg';
import styles from './styles.module.css';

const BottomCoinwebContainer = () => {
  return (
    <div id={styles.bottom_container}>
      <Typography
        fontSize="10px"
        sx={{ textAlign: 'center', marginBottom: '0px' }}
      >
        Powered by
      </Typography>
      <img src={Logo} alt="Linkmint" />
    </div>
  );
};
export default BottomCoinwebContainer;
