function getNewRound(number, lenNumber) {
  if (lenNumber < 0) return 0;
  const newNumber = Number(number).toFixed(lenNumber);
  const percent100 = 100;
  const percent = (newNumber / number) * percent100 - percent100;
  let updatedLenNumber;
  if (Math.abs(percent) <= 1) {
    updatedLenNumber = getNewRound(newNumber, lenNumber - 1);
  } else {
    updatedLenNumber += 1;
  }
  return updatedLenNumber;
}

export default function round(number) {
  const doubleNumber = String(number).split('.');

  if (doubleNumber.length < 2) return number;
  const lenNumber = doubleNumber[1].length;
  const roundNumber = getNewRound(number, lenNumber);

  if (roundNumber > lenNumber) return number;
  return Number(number).toFixed(roundNumber);
}
