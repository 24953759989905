import {
  createTheme,
  outlinedInputClasses,
  inputLabelClasses,
} from '@mui/material';
import { REACT_APP_DEVNET } from 'conf';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import variables from 'styles/variables.module.scss';

const { defaultFontFamily: fontFamily } = variables;

export const theme = createTheme(
  REACT_APP_DEVNET === 'true'
    ? {
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily,
                textTransform: 'none',
                fontWeight: 400,
                border: 'solid 1px #6ffffd',
                color: '#6ffffd',
                borderRadius: '6px',
              },
            },
          },

          MuiMenuItem: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                backgroundColor: '#adadd0',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: '#004643',
                borderRadius: '6px',
                boxShadow: 'none  !important',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#004643',
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: '#bebeff',
              },
            },
          },
          MuiSelect: {
            variants: [
              {
                props: { mobile: true },
                style: {
                  color: 'white',
                  width: '9rem',
                  mr: 2,
                  fontWeight: 500,
                  borderRadius: '6px',
                  backgroundColor: '#3778ff !important',
                },
              },
            ],
            defaultProps: {
              IconComponent: KeyboardArrowDownIcon,
            },

            styleOverrides: {
              root: {
                fontFamily,
                borderColor: 'transparent',
                height: '40px',
                backgroundColor: 'rgb(255,255,255, 0.1)',
              },
              icon: {
                color: 'rgb(255,255,255, 0.7)',
              },
            },
          },

          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: '#fff',
              },
            },
          },

          MuiTableCell: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },

          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },

          MuiFormLabel: {
            styleOverrides: {
              root: {
                [`&.${inputLabelClasses.focused}`]: {
                  color: '#fff',
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              root: {
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
                '.MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              },
            },
          },

          MuiAutocomplete: {
            styleOverrides: {
              inputRoot: {
                lineHeight: 1.2,
              },
            },
          },

          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: 'transparent',
              },
              input: {
                color: '#fff',
                height: '7px',
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
              },
              '&::placeholder': {
                color: '#adadd0 !important',
              },
              '.Mui-disabled': {
                WebkitTextFillColor: '#fff !important',
              },
              root: {
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
                backgroundColor: 'rgb(255,255,255,0.1)',
                fontFamily,
                [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: '#bebeff',
                },
                /* Reset on touch devices */
                '@media (hover: none)': {
                  [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: '#004BB9',
                  },
                },
                [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: '#bebeff',
                  },
                [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: 'red',
                  },
                [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: '#434A4F',
                  },
              },
            },
          },

          MuiTypography: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
              h2: {
                color: '#00e1d4',
                fontSize: '16px',
                fontWeight: 600,
              },
              h3: {
                color: '#adadd0',
                fontSize: '16px',
                fontWeight: 600,
              },
              h4: {
                color: '#adadd0',
                fontSize: '14px',
                fontWeight: 500,
              },

              h5: {
                color: '#adadd0',
                fontSize: '12px',
                fontWeight: 500,
              },
            },
          },

          MuiCheckbox: {
            variants: [
              {
                props: {
                  variant: 'vibrant',
                },
                style: {
                  '& .MuiSvgIcon-root': {
                    color: '#6ffffd',
                  },
                },
              },
            ],
          },

          MuiRadio: {
            variants: [
              {
                props: {
                  variant: 'dark',
                },
                style: {
                  '& .MuiSvgIcon-root': {
                    color: '#10006a',
                  },
                },
              },
            ],
          },
        },

        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          turqouis: {
            main: '#6ffffd',
            light: '#6ffffd',
            dark: '#6ffffd',
            contrastText: 'rgba(0, 0, 0, 0.87)',
          },
          background: {
            light: '#403b76',
            dark: '#403b76',
            contrastText: '#403b76',
            main: '#322c72',
          },
          lightPink: {
            light: '#bebeff',
            dark: '#bebeff',
            contrastText: '#bebeff',
            main: '#bebeff',
          },
        },
      }
    : {
        components: {
          MuiButton: {
            styleOverrides: {
              root: {
                fontFamily,
                textTransform: 'none',
                fontWeight: 400,
                border: 'solid 1px #6ffffd',
                color: '#6ffffd',
                borderRadius: '6px',
              },
            },
          },

          MuiMenuItem: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                backgroundColor: '#adadd0',
              },
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                backgroundColor: '#090a39',
                borderRadius: '6px',
                boxShadow: 'none  !important',
              },
            },
          },
          MuiAppBar: {
            styleOverrides: {
              root: {
                backgroundColor: '#0a0b43',
              },
            },
          },
          MuiIconButton: {
            styleOverrides: {
              root: {
                color: '#bebeff',
              },
            },
          },
          MuiSelect: {
            variants: [
              {
                props: { mobile: true },
                style: {
                  color: 'white',
                  width: '9rem',
                  mr: 2,
                  fontWeight: 500,
                  borderRadius: '6px',
                  backgroundColor: '#3778ff !important',
                },
              },
            ],
            defaultProps: {
              IconComponent: KeyboardArrowDownIcon,
            },

            styleOverrides: {
              root: {
                fontFamily,
                borderColor: 'transparent',
                height: '40px',
                backgroundColor: 'rgb(255,255,255, 0.1)',
              },
              icon: {
                color: 'rgb(255,255,255, 0.7)',
              },
            },
          },

          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: '#fff',
              },
            },
          },

          MuiTableCell: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },

          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
            },
          },

          MuiFormLabel: {
            styleOverrides: {
              root: {
                [`&.${inputLabelClasses.focused}`]: {
                  color: '#fff',
                },
              },
            },
          },
          MuiFormControlLabel: {
            styleOverrides: {
              root: {
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
                '.MuiFormControlLabel-label': {
                  fontSize: '14px',
                },
              },
            },
          },

          MuiAutocomplete: {
            styleOverrides: {
              inputRoot: {
                lineHeight: 1.2,
              },
            },
          },

          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: 'transparent',
              },
              input: {
                color: '#fff',
                height: '7px',
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
              },
              '&::placeholder': {
                color: '#adadd0 !important',
              },
              '.Mui-disabled': {
                WebkitTextFillColor: '#fff !important',
              },
              root: {
                '.Mui-disabled': {
                  WebkitTextFillColor: '#fff !important',
                },
                backgroundColor: 'rgb(255,255,255,0.1)',
                fontFamily,
                [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                  borderColor: '#bebeff',
                },
                /* Reset on touch devices */
                '@media (hover: none)': {
                  [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                    borderColor: '#004BB9',
                  },
                },
                [`&.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: '#bebeff',
                  },
                [`&.${outlinedInputClasses.error} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: 'red',
                  },
                [`&.${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline}`]:
                  {
                    borderColor: '#434A4F',
                  },
              },
            },
          },

          MuiTypography: {
            styleOverrides: {
              root: {
                color: '#fff',
                fontFamily,
              },
              h2: {
                color: '#00e1d4',
                fontSize: '16px',
                fontWeight: 600,
              },
              h3: {
                color: '#adadd0',
                fontSize: '16px',
                fontWeight: 600,
              },
              h4: {
                color: '#adadd0',
                fontSize: '14px',
                fontWeight: 500,
              },

              h5: {
                color: '#adadd0',
                fontSize: '12px',
                fontWeight: 500,
              },
            },
          },

          MuiCheckbox: {
            variants: [
              {
                props: {
                  variant: 'vibrant',
                },
                style: {
                  '& .MuiSvgIcon-root': {
                    color: '#6ffffd',
                  },
                },
              },
            ],
          },

          MuiRadio: {
            variants: [
              {
                props: {
                  variant: 'dark',
                },
                style: {
                  '& .MuiSvgIcon-root': {
                    color: '#10006a',
                  },
                },
              },
            ],
          },
        },

        palette: {
          neutral: {
            main: '#64748B',
            contrastText: '#fff',
          },
          turqouis: {
            main: '#6ffffd',
            light: '#6ffffd',
            dark: '#6ffffd',
            contrastText: 'rgba(0, 0, 0, 0.87)',
          },
          background: {
            light: '#403b76',
            dark: '#403b76',
            contrastText: '#403b76',
            main: '#322c72',
          },
          lightPink: {
            light: '#bebeff',
            dark: '#bebeff',
            contrastText: '#bebeff',
            main: '#bebeff',
          },
        },
      },
);
