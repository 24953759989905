import QRCode from 'react-qr-code';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import calculateQr from 'utils/calculateQr';
import {
  REACT_APP_REDIRECT_ACTION_URL,
  REACT_APP_FEATURE_FLAG,
} from '../../../conf';
import styles from './ModalSwapping.module.css';
import iconCross from '../../../assets/img/cross.svg';
import iconCoin from '../../../assets/img/logo_coinweb.svg';
import ModalMnemonic from '../../../privateKey/ModalMnemonic/ModalMnemonic';
import { Props } from '../ModalCancelled/ModalCancelled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

const ModalSwapping = (props: Props) => {
  const [openMnemonic, setOpenMnemonic] = useState(false);
  const handleOpenMnemonic = () => setOpenMnemonic(true);
  const handleCloseMnemonic = () => setOpenMnemonic(false);

  const {
    fieldTokenSell,
    fieldTokenBuy,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    priceType,
    slipPage,
  } = useSelector((state: any) => state.tokenReducer);

  let qrJson = '';
  if (props.open) {
    qrJson =
      calculateQr(
        fieldTokenSell * 10 ** fieldTokenSellChoose.exponent,
        fieldTokenBuy * 10 ** fieldTokenBuyChoose.exponent,
        fieldTokenSellChoose.hash,
        fieldTokenBuyChoose.hash,
        priceType,
        slipPage,
      ) ?? '';
  }

  const encodedQrJson = Buffer.from(qrJson).toString('base64');
  const redirectUrl = REACT_APP_REDIRECT_ACTION_URL + encodedQrJson;

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <h4>SWAPPING</h4>
            <div onClick={props.handleClose}>
              <img src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.admissible}>
            <QRCode
              size={540}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
              }}
              bgColor="transparent"
              fgColor="#fff"
              value={qrJson}
              viewBox="0 0 540 540"
            />
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>
                {fieldTokenSell} {fieldTokenSellChoose.name} for {fieldTokenBuy}{' '}
                {fieldTokenBuyChoose.name}
              </p>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.footerBlock}>
              <img src={iconCoin} alt="" />
              <p>| WALLET</p>
            </div>
          </div>
          <div className={styles.validityComment}>
            <div className={styles.validityCommentBlock}>
              <p>Confirm this transaction in your Coinweb Wallet</p>
            </div>
          </div>
          {REACT_APP_FEATURE_FLAG === 'DEV' && (
            <div className={styles.greenBlock} onClick={handleOpenMnemonic}>
              <div className={styles.greenText}>
                <p>Pay immediately</p>
              </div>
            </div>
          )}
          {isMobileDevice && (
            <div className={styles.greenSwapBlock} style={{}}>
              <a href={redirectUrl}>
                <div>
                  <p className={styles.greenSwapText}>
                    Swap with Coinweb Wallet
                  </p>
                </div>
              </a>
            </div>
          )}
          <div className={styles.greenBlock} onClick={props.handleClose}>
            <div className={styles.greenText}>
              <p>Close</p>
            </div>
          </div>
          {REACT_APP_FEATURE_FLAG === 'DEV' && (
            <ModalMnemonic
              open={openMnemonic}
              handleClose={handleCloseMnemonic}
            />
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalSwapping;
