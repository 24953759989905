import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import checkPubkey from 'utils/checkPubkey';
import handleErrorMessage from 'utils/handleErrorMessage';
import calculatePrice from 'utils/calculatePrice';
import { usePubKeyParam } from 'hooks/usePubKeyParam';
import { REACT_APP_DEVNET } from 'conf';
import { Typography } from '@mui/material';
import {
  get_user_held_custom_tokens as getUserHeldCustomTokens,
  query_tokenization_block as queryTokenizationBlock,
  get_token_balance as getTokenBalance,
  TokenQuery,
} from '@coinweb/cweb-wallet-library';
import { useWallet } from 'hooks/useWallet';
import { usePod } from 'hooks/usePod';
import getPrivKey from 'utils/getPrivKey';
import webapp from '@coinweb/webapp-library';
import {
  fieldsavePubKey,
  fieldInfoToken,
  fieldInfoTokenPrice,
  fieldInfoTokenBalance,
  tokenTableAC,
  fieldIsLogin,
  fieldBalanceSweb,
  fieldInfoTokenPriceAll,
  fieldInfoTokenBalanceAll,
  fieldInfoTokenAll,
  fieldInfoTokenHistoricalPriceAll,
  fieldTokenBuyAC,
  fieldTokenSellChooseAC,
  fieldTokenBuyChooseAC,
  fieldSwapResult,
  fieldPriceImpact,
  fieldNetworkFee,
  fieldInfoTokenSubscribed,
  fieldInfoTokenWhitelisted,
  fieldInfoTokenBalanceSubscribed,
  fieldInfoTokenBalanceWhitelisted,
  fieldInfoTokenPriceSubscribed,
  fieldInfoTokenPriceWhitelisted,
  fieldSubscribedToken,
  fieldWhitelistedToken,
  fieldLogOut,
  fieldSwapResultErrorMessage,
} from '../../redux/reducers/tokenReducer';
import styles from './Header.module.css';
import ModalLogin from '../Modals/ModalLogin/ModalLogin';
import ModalCancelled from '../Modals/ModalCancelled/ModalCancelled';
import ModalConnected from '../Modals/ModalConnected/ModalConnected';
import iconAvatar from '../../assets/img/avatar1.svg';
import iconLogo from '../../assets/img/logotype.svg';

import { ButterBar } from './ButterBar';
import { getWhitelistToken } from '../queries/whitelistToken';

const constHashSTBL =
  '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';

const constSymbolSTBL = 'STBL';
const constExponentSTBL = 18;

const constImageSTBL =
  'bafkreier7mirvue5ae4n65uzbldniziaxsligaxfgr62oyoulygpbr2n2y';

const Header = () => {
  const pubKey = usePubKeyParam();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pubKeys, setPubKey] = useState('');
  const dispatch = useDispatch();
  const [wallet, setWalletPubKey] = useWallet();
  const [subscription, setPodSubscription] = usePod();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = options.length === 0;

  const [openLogin, setOpenLogin] = useState(false);
  const [openCancelled, setOpenCancelled] = useState(false);
  const [openConnected, setOpenConnected] = useState(false);

  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);
  const handleCloseCancelled = () => setOpenCancelled(false);
  const handleCloseConnected = () => setOpenConnected(false);

  const { isLogin, tokenSweb, subscribedToken, tokenPrice } = useSelector(
    (state) => state.tokenReducer,
  );

  const navigate = useNavigate();

  const logOut = () => {
    subscription.subscribe().unsubscribe();
    localStorage.removeItem('privKey');
    dispatch(fieldIsLogin(false));
    dispatch(fieldLogOut());
    navigate('/');
  };

  useEffect(() => {
    if (!loading || wallet === undefined) {
      return undefined;
    }
    let active = true;
    try {
      (async () => {
        const tokens = await getUserHeldCustomTokens(wallet);
        const swebBalance = await getTokenBalance(wallet, null);
        const onChainQuery = [];
        const tokenPriceQuery = [];
        const tokenSupplyQuery = [];
        const tokenBondingCurve = [];
        tokens.forEach((token) => {
          onChainQuery.push({
            TokenQuery: [token, TokenQuery.LastExtraFields],
          });
          tokenPriceQuery.push({
            TokenQuery: [token, TokenQuery.CurrentPrice],
          });
          tokenSupplyQuery.push({
            TokenQuery: [token, TokenQuery.TotalSupply],
          });
          tokenBondingCurve.push({
            TokenQuery: [token, TokenQuery.BondingCurve],
          });
        });

        const validateTokens = await queryTokenizationBlock(
          wallet,
          tokenBondingCurve,
        );

        let j = 0;
        validateTokens.forEach((token) => {
          if (
            token.TokenQueryResult.BondingCurve.Ok === null ||
            token.TokenQueryResult.BondingCurve.Ok === undefined
          ) {
            onChainQuery.splice(j, 1);
            tokenPriceQuery.splice(j, 1);
            tokenSupplyQuery.splice(j, 1);
            tokens.splice(j, 1);
            j -= 1;
          }
          j += 1;
        });
        const tokensInfo = await queryTokenizationBlock(wallet, onChainQuery);

        const tokensPrice = await queryTokenizationBlock(
          wallet,
          tokenPriceQuery,
        );

        const tokensSupply = [];
        // eslint-disable-next-line
        for (const token of tokens) {
          const tokenBalanceW = await getTokenBalance(wallet, token); // eslint-disable-line
          tokensSupply.push(tokenBalanceW);
        }
        // const tokensSupply = await queryTokenizationBlock(
        //  wallet,
        //  tokenSupplyQuery,
        // );

        // const subcrebe_tokens

        // tokens
        const tokenSet = new Set(tokens);

        const subscribedTokenNew = subscribedToken.filter(
          (e) => !tokenSet.has(e),
        );

        const subscribedTokenSet = new Set(subscribedTokenNew);
        const whitelistedTokenNowError = await getWhitelistToken();
        const whitelistedTokenNow = [];
        const correctString = '0x';

        whitelistedTokenNowError.forEach((token) => {
          if (token.slice(0, 2) === correctString)
            whitelistedTokenNow.push(token);
          else whitelistedTokenNow.push(correctString + token);
        });

        let whitelistedTokenNew = whitelistedTokenNow.filter(
          (e) => !tokenSet.has(e),
        );

        whitelistedTokenNew = whitelistedTokenNew.filter(
          (e) => !subscribedTokenSet.has(e),
        );

        const subscribedTokenFields = [];
        const subscribedTokenPrice = [];
        const subscribedTokenSupply = [];
        const subscribedTokenBondingCurve = [];
        subscribedTokenNew.forEach((token) => {
          subscribedTokenFields.push({
            TokenQuery: [token, TokenQuery.LastExtraFields],
          });
          subscribedTokenPrice.push({
            TokenQuery: [token, TokenQuery.CurrentPrice],
          });
          subscribedTokenBondingCurve.push({
            TokenQuery: [token, TokenQuery.BondingCurve],
          });
        });
        const subscribedValidateTokens = await queryTokenizationBlock(
          wallet,
          subscribedTokenBondingCurve,
        );

        j = 0;
        subscribedValidateTokens.forEach((token) => {
          if (
            token.TokenQueryResult.BondingCurve.Ok === null ||
            token.TokenQueryResult.BondingCurve.Ok === undefined
          ) {
            subscribedTokenFields.splice(j, 1);
            subscribedTokenPrice.splice(j, 1);
            subscribedTokenNew.splice(j, 1);
            j -= 1;
          }
          j += 1;
        });
        // eslint-disable-next-line
        for (const token of subscribedTokenNew) {
          const tokenBalanceWA = await getTokenBalance(wallet, token); // eslint-disable-line
          subscribedTokenSupply.push(tokenBalanceWA);
        }
        const subscribedTokenFieldsQ = await queryTokenizationBlock(
          wallet,
          subscribedTokenFields,
        );

        const subscribedTokenPriceQ = await queryTokenizationBlock(
          wallet,
          subscribedTokenPrice,
        );

        const whitelistedTokenFields = [];
        const whitelistedTokenPrice = [];
        const whitelistedTokenSupply = [];
        const whitelistedTtokenBondingCurve = [];
        whitelistedTokenNew.forEach((token) => {
          whitelistedTokenFields.push({
            TokenQuery: [token, TokenQuery.LastExtraFields],
          });
          whitelistedTokenPrice.push({
            TokenQuery: [token, TokenQuery.CurrentPrice],
          });
          whitelistedTtokenBondingCurve.push({
            TokenQuery: [token, TokenQuery.BondingCurve],
          });
        });
        const whitelistedValidateTokens = await queryTokenizationBlock(
          wallet,
          whitelistedTtokenBondingCurve,
        );

        j = 0;
        whitelistedValidateTokens.forEach((token) => {
          if (
            token.TokenQueryResult.BondingCurve.Ok === null ||
            token.TokenQueryResult.BondingCurve.Ok === undefined
          ) {
            whitelistedTokenFields.splice(j, 1);
            whitelistedTokenPrice.splice(j, 1);
            whitelistedTokenNew.splice(j, 1);
            j -= 1;
          }
          j += 1;
        });
        // eslint-disable-next-line
        for (const token of whitelistedTokenNew) {
          const tokenBalanceWA = await getTokenBalance(wallet, token); // eslint-disable-line
          whitelistedTokenSupply.push(tokenBalanceWA);
        }
        const whitelistedTokenFieldsQ = await queryTokenizationBlock(
          wallet,
          whitelistedTokenFields,
        );

        const whitelistedTokenPriceQ = await queryTokenizationBlock(
          wallet,
          whitelistedTokenPrice,
        );

        const buyCountParam = searchParams.get('buy_count');
        if (buyCountParam) {
          navigate('');
          dispatch(fieldTokenBuyAC(buyCountParam));
        }
        /* const sellCountParam = searchParams.get('sell_count');
        if (sellCountParam) {
          dispatch(fieldTokenSellAC(sellCountParam));
        } */
        const buyTokenParam = searchParams.get('buy_token');
        const sellTokenParam = searchParams.get('sell_token');
        if (buyTokenParam !== sellTokenParam) {
          navigate('');
          let imgS = '';
          let nameS = '';
          let exponentS = 0;
          let idS = 1;
          if (sellTokenParam) {
            if (sellTokenParam === 'CWEB') {
              idS = 0;
              exponentS = tokenSweb.exponent;
              dispatch(
                fieldTokenSellChooseAC({
                  id: idS,
                  name: tokenSweb.text,
                  img: tokenSweb.img,
                  exponent: exponentS,
                  balance: swebBalance,
                }),
              );
            } else {
              idS = tokens.indexOf(sellTokenParam);
              if (sellTokenParam === constHashSTBL && idS !== -1) {
                dispatch(
                  fieldTokenSellChooseAC({
                    id: idS + 1,
                    hash: constHashSTBL,
                    name: constSymbolSTBL,
                    img: '',
                    imgH: constImageSTBL,
                    exponent: constExponentSTBL,
                    balance: tokensSupply[idS],
                  }),
                );
              } else if (idS !== -1) {
                tokensInfo[idS].TokenQueryResult.LastExtraFields.Ok.forEach(
                  (element) => {
                    if (element.name === 'ipfs_image_url')
                      imgS = element.content;
                    if (element.name === 'symbol') nameS = element.content;
                    if (element.name === 'exponent')
                      exponentS = Number(element.content);
                  },
                );
                dispatch(
                  fieldTokenSellChooseAC({
                    id: idS + 1,
                    hash: sellTokenParam,
                    name: nameS,
                    img: '',
                    imgH: imgS,
                    exponent: exponentS,
                    balance: tokensSupply[idS],
                  }),
                );
              }
            }
          }
          let imgB = '';
          let nameB = '';
          let exponentB = 0;
          let idB = -1;
          if (buyTokenParam) {
            if (buyTokenParam === 'CWEB') {
              idB = 0;
              exponentB = tokenSweb.exponent;
              dispatch(
                fieldTokenBuyChooseAC({
                  id: idB,
                  name: tokenSweb.text,
                  img: tokenSweb.img,
                  exponent: exponentB,
                  balance: swebBalance,
                }),
              );
            } else {
              const tokenHeldFind = tokens.indexOf(buyTokenParam);

              const tokenSubscribedFind =
                subscribedTokenNew.indexOf(buyTokenParam);

              const tokenWhitelistedFind =
                whitelistedTokenNew.indexOf(buyTokenParam);

              if (buyTokenParam === constHashSTBL) {
                if (tokenHeldFind !== -1)
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id: tokenHeldFind + 1,
                      hash: constHashSTBL,
                      name: constSymbolSTBL,
                      img: '',
                      imgH: constImageSTBL,
                      exponent: constExponentSTBL,
                      balance: tokensSupply[tokenHeldFind],
                    }),
                  );
                if (tokenSubscribedFind !== -1)
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id: tokenSubscribedFind + tokens.length + 1,
                      hash: constHashSTBL,
                      name: constSymbolSTBL,
                      img: '',
                      imgH: constImageSTBL,
                      exponent: constExponentSTBL,
                      balance: subscribedTokenSupply[tokenSubscribedFind],
                    }),
                  );
                if (tokenWhitelistedFind !== -1)
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id:
                        tokenWhitelistedFind +
                        subscribedTokenNew.length +
                        tokens.length +
                        1,
                      hash: constHashSTBL,
                      name: constSymbolSTBL,
                      img: '',
                      imgH: constImageSTBL,
                      exponent: constExponentSTBL,
                      balance: whitelistedTokenSupply[tokenWhitelistedFind],
                    }),
                  );
              } else {
                if (tokenHeldFind !== -1) {
                  tokensInfo[
                    tokenHeldFind
                  ].TokenQueryResult.LastExtraFields.Ok.forEach((element) => {
                    if (element.name === 'ipfs_image_url')
                      imgB = element.content;
                    if (element.name === 'symbol') nameB = element.content;
                    if (element.name === 'exponent')
                      exponentB = Number(element.content);
                  });
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id: tokenHeldFind + 1,
                      hash: buyTokenParam,
                      name: nameB,
                      img: '',
                      imgH: imgB,
                      exponent: exponentB,
                      balance: tokensSupply[tokenHeldFind],
                    }),
                  );
                }
                if (tokenSubscribedFind !== -1) {
                  subscribedTokenFieldsQ[
                    tokenSubscribedFind
                  ].TokenQueryResult.LastExtraFields.Ok.forEach((element) => {
                    if (element.name === 'ipfs_image_url')
                      imgB = element.content;
                    if (element.name === 'symbol') nameB = element.content;
                    if (element.name === 'exponent')
                      exponentB = Number(element.content);
                  });
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id: tokenSubscribedFind + tokens.length + 1,
                      hash: buyTokenParam,
                      name: nameB,
                      img: '',
                      imgH: imgB,
                      exponent: exponentB,
                      balance: subscribedTokenSupply[tokenSubscribedFind],
                    }),
                  );
                }
                if (tokenWhitelistedFind !== -1) {
                  whitelistedTokenFieldsQ[
                    tokenWhitelistedFind
                  ].TokenQueryResult.LastExtraFields.Ok.forEach((element) => {
                    if (element.name === 'ipfs_image_url')
                      imgB = element.content;
                    if (element.name === 'symbol') nameB = element.content;
                    if (element.name === 'exponent')
                      exponentB = Number(element.content);
                  });
                  dispatch(
                    fieldTokenBuyChooseAC({
                      id:
                        tokenWhitelistedFind +
                        subscribedTokenNew.length +
                        tokens.length +
                        1,
                      hash: buyTokenParam,
                      name: nameB,
                      img: '',
                      imgH: imgB,
                      exponent: exponentB,
                      balance: whitelistedTokenSupply[tokenWhitelistedFind],
                    }),
                  );
                }
              }
            }
          }
          if (sellTokenParam && buyTokenParam) {
            if (buyCountParam) {
              let price = {
                loading: false,
                networkFee: '0',
                priceImpact: '0',
                tokenAmount: '0',
                err: undefined,
              };
              try {
                price = await calculatePrice(
                  wallet,
                  buyCountParam * 10 ** exponentB,
                  sellTokenParam,
                  buyTokenParam,
                  'buy',
                  exponentS,
                );
                if (price.tokenAmount > 0) dispatch(fieldSwapResult(true));
                else {
                  dispatch(
                    fieldSwapResultErrorMessage(handleErrorMessage(price.err)),
                  );
                  dispatch(fieldSwapResult(false));
                }
                dispatch(fieldPriceImpact(price.priceImpact));
                dispatch(fieldNetworkFee(price.networkFee));
                dispatch(fieldTokenBuyAC(buyCountParam, price.tokenAmount));
              } catch {
                dispatch(fieldSwapResult(false));
                dispatch(
                  fieldSwapResultErrorMessage(handleErrorMessage(price.err)),
                );
                dispatch(fieldTokenBuyAC(buyCountParam, price.tokenAmount));
                dispatch(fieldPriceImpact(price.priceImpact));
                dispatch(fieldNetworkFee(price.networkFee));
              }
            } /* else if (sellCountParam) {
            let price = {
              networkFee: '0',
              priceImpact: '0',
              tokenAmount: '0',
            };
            try {
              price = await calculatePrice(
                wallet,
                sellCountParam * 10 ** exponentS,
                sellTokenParam,
                buyTokenParam,
                'sell',
                exponentB,
              );
              if (price.tokenAmount > 0) dispatch(fieldSwapResult(true));
              else dispatch(fieldSwapResult(false));
              dispatch(fieldPriceImpact(price.priceImpact));
              dispatch(fieldNetworkFee(price.networkFee));
              dispatch(fieldTokenSellAC(sellCountParam, price.tokenAmount));
            } catch {
              dispatch(fieldSwapResult(false));
              dispatch(fieldTokenSellAC(sellCountParam, price.tokenAmount));
              dispatch(fieldPriceImpact(price.priceImpact));
              dispatch(fieldNetworkFee(price.networkFee));
            }
          } */
          }
        }

        if (active) {
          setOptions(tokens);
          dispatch(fieldIsLogin(true));
          // dispatch(fieldPriceSweb());
          dispatch(fieldsavePubKey(tokens));
          dispatch(fieldInfoToken(tokensInfo));
          dispatch(fieldInfoTokenPrice(tokensPrice));
          dispatch(fieldInfoTokenBalance(tokensSupply));
          dispatch(fieldBalanceSweb(swebBalance));
          dispatch(fieldInfoTokenSubscribed(subscribedTokenFieldsQ));
          dispatch(fieldInfoTokenWhitelisted(whitelistedTokenFieldsQ));
          dispatch(fieldInfoTokenBalanceSubscribed(subscribedTokenSupply));
          dispatch(fieldInfoTokenBalanceWhitelisted(whitelistedTokenSupply));
          dispatch(fieldInfoTokenPriceSubscribed(subscribedTokenPriceQ));
          dispatch(fieldInfoTokenPriceWhitelisted(whitelistedTokenPriceQ));
          dispatch(fieldSubscribedToken(subscribedTokenNew));
          dispatch(fieldWhitelistedToken(whitelistedTokenNew));
        }
        setOpenConnected(true);

        queryTokenizationBlock(wallet, [{ TokenFilterQuery: {} }]).then(
          async (allToken) => {
            const onChainQueryAll = [];
            const tokenPriceQueryAll = [];
            const tokenSupplyQueryAll = [];
            const tokenBondingCurveAll = [];
            const tokenHistoricalPriceAll = [];

            const time = {
              start: new Date() - 1,
              finish: new Date().getTime(),
            };

            allToken[0].TokenFilterResult.forEach((token) => {
              onChainQueryAll.push({
                TokenQuery: [token.token, TokenQuery.LastExtraFields],
              });
              tokenPriceQueryAll.push({
                TokenQuery: [token.token, TokenQuery.CurrentPrice],
              });
              tokenSupplyQueryAll.push({
                TokenQuery: [token.token, TokenQuery.TotalSupply],
              });
              tokenBondingCurveAll.push({
                TokenQuery: [token.token, TokenQuery.BondingCurve],
              });
              tokenHistoricalPriceAll.push({
                TokenHistoricalPriceQuery: {
                  token: token.token,
                  start: time.start,
                  finish: time.finish,
                },
              });
            });
            const validateTokensAll = await queryTokenizationBlock(
              wallet,
              tokenBondingCurveAll,
            );

            let i = 0;
            validateTokensAll.forEach((token) => {
              if (
                token.TokenQueryResult.BondingCurve.Ok === null ||
                token.TokenQueryResult.BondingCurve.Ok === undefined
              ) {
                onChainQueryAll.splice(i, 1);
                tokenPriceQueryAll.splice(i, 1);
                tokenSupplyQueryAll.splice(i, 1);
                allToken[0].TokenFilterResult.splice(i, 1);
                tokenHistoricalPriceAll.splice(i, 1);
                i -= 1;
              }
              i += 1;
            });

            const tokensInfoAll = await queryTokenizationBlock(
              wallet,
              onChainQueryAll,
            );

            const tokensPriceAll = await queryTokenizationBlock(
              wallet,
              tokenPriceQueryAll,
            );

            const tokensSupplyAll = [];
            // eslint-disable-next-line
            for (const token of allToken[0].TokenFilterResult) {
              const tokenBalanceWA = await getTokenBalance(wallet, token.token); // eslint-disable-line
              tokensSupplyAll.push(tokenBalanceWA);
            }

            queryTokenizationBlock(wallet, tokenHistoricalPriceAll).then(
              (data) => dispatch(fieldInfoTokenHistoricalPriceAll(data)),
            );

            dispatch(tokenTableAC(allToken));
            dispatch(fieldInfoTokenAll(tokensInfoAll));
            dispatch(fieldInfoTokenPriceAll(tokensPriceAll));
            dispatch(fieldInfoTokenBalanceAll(tokensSupplyAll));
          },
        );
      })();
    } catch {
      console.log('error');
    }

    return () => {
      active = false;
    };
  }, [loading, wallet]);

  useEffect(() => {
    if (wallet === undefined && pubKey !== null) {
      let subFlag = 1;
      try {
        const key = getPrivKey(localStorage.getItem('privKey'));
        setPodSubscription(key.pubKey, key.privKey);
      } catch {
        subFlag = 0;
      }
      if (checkPubkey(pubKey) && subFlag === 1) {
        setPubKey(pubKey);
        setWalletPubKey(pubKey);
        setOpen(true);
      } else {
        navigate('/');
      }
    }
    if (subscription) {
      let privKey = getPrivKey(localStorage.getItem('privKey'));
      privKey = privKey.privKey;
      subscription.subscribe((x) => {
        const subscriptionType = x.data.subscribeWebapp.type;
        if (subscriptionType === 'DATA') {
          const key = webapp.decode(privKey, x.data.subscribeWebapp.payload[0]);
          if (pubKey !== key.user_pubkey) {
            dispatch(fieldIsLogin(false));
            dispatch(fieldLogOut());
            navigate('/');
          }

          const difference = key.subscribed_token_list.filter(
            (element) => !subscribedToken.includes(element),
          );
          if (difference.length > 0)
            dispatch(fieldSubscribedToken(key.subscribed_token_list));
        }
        if (subscriptionType === 'LOGIN') {
          dispatch(fieldIsLogin(false));
          dispatch(fieldLogOut());
          navigate('/');
        }
        if (subscriptionType === 'LOGOFF') {
          logOut();
        }
      });
    }
  }, [pubKeys, searchParams, setSearchParams]);

  const handleChange = (key) => {
    setOpenLogin(false);
    setWalletPubKey(key);
    setOpen(true);
    dispatch(fieldIsLogin(true));
  };

  return (
    <>
      {!isLogin && <ButterBar />}
      <header className={styles.container}>
        <div className={styles.header}>
          <div className={styles.blockLinks}>
            <div>
              <NavLink to={`/${pubKey}`} className={styles.linkLogo}>
                <img src={iconLogo} alt="" style={{ height: '26px ' }} />
                {REACT_APP_DEVNET === 'true' && (
                  <Typography
                    fontSize="18px"
                    sx={{
                      marginLeft: '70px',
                      lineHeight: 1,
                      color: '#fff',
                      fontWeight: 900,
                      fontFamily: 'inherit',
                    }}
                  >
                    DEVNET
                  </Typography>
                )}
              </NavLink>
            </div>
          </div>
          <div className={styles.centralBlock}>
            <div className={styles.linkStyle}>
              <div className={styles.navStyle}>
                <NavLink
                  end="true"
                  to={`/${pubKey}`}
                  className={styles.link}
                  style={({ isActive }) => ({
                    background: isActive
                      ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                      : 'rgba(117, 190, 218, 0.0)',
                  })}
                >
                  SWAP
                </NavLink>
                <NavLink
                  end="true"
                  to={`/${pubKey}/token`}
                  className={styles.link}
                  style={({ isActive }) => ({
                    background:
                      isActive ||
                      window.location.pathname.match(/\/.{66}\/token\/.+/)
                        ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                        : 'rgba(117, 190, 218, 0.0)',
                  })}
                >
                  CHART
                </NavLink>
              </div>
            </div>
          </div>
          <div className={styles.buttonDiv} onClick={handleOpenLogin}>
            {isLogin && (
              <div className={styles.validityBlock}>
                <button className={styles.buttonLogin}>
                  <p className={styles.buttonText}> {pubKey} </p>
                </button>
                <div id={styles.avatarImgContainer}>
                  <img src={iconAvatar} alt="" id={styles.avatarImg} />
                </div>
              </div>
            )}
          </div>
        </div>
        {isLogin ? (
          <ModalLogin
            open={openLogin}
            handleClose={handleCloseLogin}
            onChange={handleChange}
            token={pubKey}
          />
        ) : (
          <ModalLogin
            open={openLogin}
            handleClose={handleCloseLogin}
            onChange={handleChange}
            activeLogin={false}
          />
        )}
        <ModalCancelled
          open={openCancelled}
          handleClose={handleCloseCancelled}
        />
        <ModalConnected
          open={openConnected}
          handleClose={handleCloseConnected}
        />
      </header>
      <div className={styles.footerBlock}>
        <div className={styles.footerStyle}>
          <div className={styles.navStyle}>
            <NavLink
              end="true"
              to={`/${pubKey}`}
              className={styles.link}
              style={({ isActive }) => ({
                background: isActive
                  ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                  : 'rgba(117, 190, 218, 0.0)',
              })}
            >
              SWAP
            </NavLink>
            <NavLink
              end="true"
              to={`/${pubKey}/token`}
              className={styles.link}
              style={({ isActive }) => ({
                background:
                  isActive ||
                  window.location.pathname.match(/\/.{66}\/token\/.+/)
                    ? 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(11,187,177,1) 0%, rgba(6,120,189,1) 100%)'
                    : 'rgba(117, 190, 218, 0.0)',
              })}
            >
              CHART
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
