type IHookStateInitialSetter<S> = () => S;

type IHookStateSetter<S> = ((prevState: S) => S) | (() => S);
export type IHookStateSetAction<S> = S | IHookStateSetter<S>;

type IHookStateResolvable<S> =
  | S
  | IHookStateInitialSetter<S>
  | IHookStateSetter<S>;

export function resolveHookState<S, C extends S>(
  nextState: IHookStateResolvable<S>,
  currentState?: C,
): S {
  if (typeof nextState === 'function') {
    return nextState.length
      ? (nextState as Function)(currentState)
      : (nextState as Function)();
  }

  return nextState;
}
