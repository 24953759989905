import styles from './MyButton.module.css';

type MyButtonProps = {
  className: any;
  classNameText: any;
  title: any;
  disabled: any;
};

const MyButton = (props: MyButtonProps) => {
  return (
    <button
      disabled={props.disabled}
      className={`${styles.button} ${props.className}`}
    >
      <p className={`${styles.buttonText} ${props.classNameText}`}>
        {props.title}
      </p>
    </button>
  );
};

export default MyButton;
