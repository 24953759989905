import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useState } from 'react';
import webapp from '@coinweb/webapp-library';
import styles from './styles.module.scss';
import iconCross from '../../assets/img/cross.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

type ModalWebappProps = {
  open: boolean;
  qrJson: string;
  handleClose: () => void;
};

const ModalWebapp = (props: ModalWebappProps) => {
  const [userPubkey, setUserPubkey] = useState('');
  const [subscribedTokenList, setSubscribedTokenList] = useState('');

  const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserPubkey(e.target.value);
  };

  const handlerInputMas = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubscribedTokenList(e.target.value);
  };

  const handlerPay = async () => {
    let sentSubscribedTokenList = [];
    if (subscribedTokenList)
      sentSubscribedTokenList = JSON.parse(subscribedTokenList);
    await webapp.on_webapp_qr_json(
      props.qrJson,
      userPubkey,
      sentSubscribedTokenList,
    );
    props.handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <h4>Test</h4>
            <div onClick={props.handleClose}>
              <img src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>Introduce a user pubkey</p>
            </div>
          </div>
          <div className={styles.inputBlock}>
            <input type="text" onChange={handlerInput} />
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>Introduce a user subscribed token list</p>
            </div>
          </div>
          <div className={styles.inputBlock}>
            <input type="text" placeholder="[...]" onChange={handlerInputMas} />
          </div>
          <div className={styles.greenBlock} onClick={handlerPay}>
            <div className={styles.greenText}>
              <p>Sent</p>
            </div>
          </div>
          <div className={styles.redBlock} onClick={props.handleClose}>
            <div className={styles.redText}>
              <p>Dismiss</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalWebapp;
