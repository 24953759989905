import TableTokenBody from 'components/TableToken/TableTokenBody/TableTokenBody';
import BottomCoinwebContainer from 'components/Containers/BottomCoinwebContainer';
import styles from './Token.module.css';

const Token = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.table}>
          <TableTokenBody />
        </div>
      </div>
      <BottomCoinwebContainer />
    </div>
  );
};
export default Token;
