import round from 'utils/round';

export default function createChartData(data: any, exponent: number) {
  let chartDataNow: any[] = [];
  let oldPriceNow = -1;
  data.forEach((element: any) => {
    if (oldPriceNow === -1)
      oldPriceNow = Number(round(Number(element[1]) * 10 ** (exponent - 18)));
    const date = new Date(element[0]);
    chartDataNow = [
      {
        primary: date,
        secondary: Number(round(Number(element[1]) * 10 ** (exponent - 18))),
      },
    ].concat(chartDataNow);
  });

  return {
    oldprice: oldPriceNow,
    data: chartDataNow,
  };
}
