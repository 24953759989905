import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import styles from './ModalSubmitted.module.css';
import iconTransaction from '../../../assets/img/transaction.png';
import iconOk from '../../../assets/img/connected.svg';
import { Props } from '../ModalCancelled/ModalCancelled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

const ModalSubmitted = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.admissible}>
            <img src={iconOk} alt="" />
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>Transaction submitted</p>
            </div>
          </div>
          <div className={styles.validityComment}>
            <div className={styles.validityCommentBlock}>
              <p>
                <img src={iconTransaction} alt="" />
                View on Coinweb Explorer
              </p>
            </div>
          </div>
          <div className={styles.greenBlock} onClick={props.handleClose}>
            <div className={styles.greenText}>
              <p>Close</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalSubmitted;
