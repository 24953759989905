import { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useWallet } from 'hooks/useWallet';
import { usePod } from 'hooks/usePod';
import QRCode from 'react-qr-code';
import getPrivKey from 'utils/getPrivKey';
import webapp from '@coinweb/webapp-library';
import { fieldSubscribedToken } from '../../redux/reducers/tokenReducer';
import { REACT_APP_REDIRECT_URL, REACT_APP_FEATURE_FLAG } from '../../conf';
import Logo from '../../images/logo_login.svg';
import { CenteredBox } from '../../components/Containers/Box';
import styles from './Login.module.css';
import ModalWebapp from '../../privateKey/ModalWebapp/ModalWebapp';

type LoginProps = {
  onLogin: any;
  qr: any;
};

const LoginButtons = (props: LoginProps) => {
  const encodedString = Buffer.from(props.qr).toString('base64');
  const redirectUrl = REACT_APP_REDIRECT_URL + encodedString;

  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  if (isMobileDevice)
    return (
      <CenteredBox className={styles.buttonWrapper}>
        <Button
          className={styles.loginColorText}
          onClick={props.onLogin}
          sx={{ margin: 'auto 10px' }}
        >
          Scan QR code to login
        </Button>
        <a
          className={styles.walletLink}
          style={{ margin: 'auto 10px' }}
          href={redirectUrl}
        >
          <Button className={styles.loginWalletColorText}>
            Login with Coinweb Wallet
          </Button>
        </a>
      </CenteredBox>
    );

  return (
    <Button
      className={styles.loginColorText}
      onClick={props.onLogin}
      sx={{ margin: '120px auto auto auto' }}
    >
      Scan QR code to login
    </Button>
  );
};

export const Login = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [openWebapp, setOpenWebapp] = useState(false);
  const [qrJson, setQrJson] = useState('');

  const handleOpenWebapp = () => setOpenWebapp(true);
  const handleCloseWebapp = () => setOpenWebapp(false);
  const [, setWalletPubKey] = useWallet();
  const [subscription, setPodSubscription] = usePod();
  const [login, setLogin] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!searchParams) return;
    const privkeyParam = searchParams.get('key');
    let pubKey = '';
    let privKey = '';

    if (privkeyParam !== null) {
      const key = getPrivKey(privkeyParam);
      pubKey = key.pubKey;
      privKey = key.privKey;
    } else {
      try {
        const key = getPrivKey(localStorage.getItem('privKey'));
        pubKey = key.pubKey;
        privKey = key.privKey;
      } catch {
        const key = webapp.create_webapp_keys();
        pubKey = key.pubKey;
        privKey = key.privKey;
      }
    }
    localStorage.setItem(
      'privKey',
      Buffer.from(JSON.stringify(privKey)).toString('base64'),
    );
    setPodSubscription(pubKey, privKey);
    const qr = webapp.create_webapp_qr(pubKey);
    setQrJson(qr);
  }, [setSearchParams, setQrJson, searchParams, setPodSubscription]);

  /* const handleChange = (key) => {
    setWalletPubKey(key);
    navigate(`/${key}`);
  }; */

  const handleChange = () => {
    setLogin(true);
  };

  useEffect(() => {
    if (!localStorage.getItem('privKey')) {
      return;
    }
    if (subscription && localStorage.getItem('privKey')) {
      const tokenBuyParam = searchParams.get('token_buy');
      const tokenSellParam = searchParams.get('token_sell');
      let privKey = getPrivKey(localStorage.getItem('privKey'));
      privKey = privKey.privKey;
      subscription.subscribe((x: any) => {
        const subscriptionType = x.data.subscribeWebapp.type;
        if (subscriptionType === 'DATA') {
          try {
            const key = webapp.decode(
              privKey,
              x.data.subscribeWebapp.payload[0],
            );
            setWalletPubKey(key.user_pubkey);
            dispatch(fieldSubscribedToken(key.subscribed_token_list));
            if (tokenBuyParam !== null) {
              const sellUrl = '/?buy_token=';
              if (tokenSellParam !== null) {
                const sellUrlAdd = '&sell_token=';
                navigate(
                  key.user_pubkey +
                    sellUrl +
                    tokenBuyParam +
                    sellUrlAdd +
                    tokenSellParam,
                );
              } else navigate(key.user_pubkey + sellUrl + tokenBuyParam);
            } else navigate(`/${key.user_pubkey}`);
          } catch {
            // eslint-disable-next-line no-console
            console.warn('privKey couldnt be decoded');
          }
        }
        if (subscriptionType === 'LOGOFF') {
          subscription.subscribe().unsubscribe();
          localStorage.removeItem('privKey');
          const key = webapp.create_webapp_keys();
          const pubKeyNew = key.pubKey;
          const privKeyNew = key.privKey;
          localStorage.setItem(
            'privKey',
            Buffer.from(JSON.stringify(privKeyNew)).toString('base64'),
          );
          setPodSubscription(pubKeyNew, privKeyNew);
          const qr = webapp.create_webapp_qr(pubKeyNew);
          setQrJson(qr);
        }
      });
    }
  }, [
    setPodSubscription,
    dispatch,
    navigate,
    searchParams,
    setWalletPubKey,
    subscription,
  ]);

  return (
    <CenteredBox className={styles.loginWrapper}>
      {login ? (
        <div className={styles.admissible}>
          <QRCode
            size={540}
            style={{
              width: '200px',
              height: '200px',
            }}
            bgColor="transparent"
            fgColor="#fff"
            value={qrJson}
            viewBox="0 0 540 540"
          />
          {/* <LoginQrScanner onScan={handleChange} /> */}
        </div>
      ) : (
        <LoginButtons onLogin={handleChange} qr={qrJson} />
      )}
      <Box className={styles.loginBottomBox}>
        <Typography fontSize="14px" className={styles.loginText}>
          Powered by
        </Typography>
        <img src={Logo} alt="Linkmint" className={styles.loginLogo} />
      </Box>
      {REACT_APP_FEATURE_FLAG === 'DEV' && (
        <div className={styles.greenBlock} onClick={handleOpenWebapp}>
          <div className={styles.greenText}>
            <p>Login By Emulating wallet-app</p>
          </div>
        </div>
      )}
      {REACT_APP_FEATURE_FLAG === 'DEV' && (
        <ModalWebapp
          open={openWebapp}
          qrJson={qrJson}
          handleClose={handleCloseWebapp}
        />
      )}
    </CenteredBox>
  );
};
