import { TrackError } from '@coinweb/cweb-wallet-library';

export default function handleErrorMessage(err: TrackError | string) {
  if (err === TrackError.NotEnoughFunds) return 'There is not enough funds.';
  if (err === TrackError.PayToBroadcasterFailed)
    return 'Pay to broadcater failed.';
  if (err === TrackError.TokenAlreadyExists) return 'Token already exist.';
  if (err === TrackError.TokenDoesNotExist) return 'Token does not exist.';
  if (err === TrackError.InvalidPublicKey) return 'Invalid public key.';
  if (err === TrackError.Overflow) return 'Overflow.';
  if (err === TrackError.UserIsNotMinter) return 'User is not a minter.';
  if (err === TrackError.UserIsNotAdmin) return 'User is not an admin.';
  if (err === TrackError.Canceled) return 'Canceled.';
  if (err === TrackError.BondingDoesNotExist)
    return 'Bonding curve does not exist.';
  if (err === TrackError.BondingCurveReachDomain)
    return 'Bonding curve reaches the domain.';
  if (err === TrackError.TokenNotMintable) return 'Token is not mintable.';
  if (err === TrackError.CalculationFailed) return 'Price calculation failed.';
  if (err === TrackError.LimitNotFit) return 'Limit does nit fit.';
  if (err === TrackError.NotMonotonicallyDecreasingCurve)
    return 'Bonding Curve is not monotonically decrasing.';
  if (err === TrackError.NotMonotonicallyDecreasingWide)
    return 'Wide is not monotonically decrasing.';
  if (err === TrackError.CwebSupplyGreaterThenDomain)
    return 'Cweb supply greater than its domain.';
  if (err === TrackError.WrongOrderSegment)
    return 'Can not buy more cweb than available in the curve';
  if (err === TrackError.ZeroAmount) return 'Amount is zero.';
  if (err === TrackError.InitialTokenSupplyMustBeZero)
    return 'Initial token supply must be zero.';
  if (err === TrackError.BondingCurveFrozen) return 'Bonding curve is frozen.';
  if (err === TrackError.CanNotMintMoreThanMaxSupply)
    return 'Can not mint more than maximum supply.';
  if (err === TrackError.NakedTokensIncreased)
    return 'Naked Token is increased.';
  if (err === TrackError.TokenNotNaked) return 'Token is not naked.';
  if (err === TrackError.CwebSupplyDecreased)
    return 'Cweb supply is decreased.';
  if (err === TrackError.TokenRateLimiterError)
    return 'Token rate limiter error.';
  if (err === TrackError.TokenRateLimit) return 'Token rate limited.';
  if (err === TrackError.ExchangeQueueIsEmpty)
    return 'Exchange queue is empty.';
  if (err === TrackError.TokenAmountGreaterThenRateLimiterMax)
    return 'Token amount is greater than maximum rate limiter.';
  if (err === TrackError.TokenRateLimiterRefillDurationError)
    return 'Token rate limiter refill duration error.';
  if (err === TrackError.TokenRateLimiterRefillGreaterThenOneDay)
    return 'Token rate limiter refill is greater than one day.';
  if (err === TrackError.SaleNotStarted) return 'Sale is not started.';
  if (typeof err === 'string') return err;
  return '';
}
