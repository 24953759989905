export default function createHexString(bytes: number[]) {
  const hex = [];
  for (let i = 0; i < bytes.length; i += 1) {
    const current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
    hex.push((current >>> 4).toString(16)); // eslint-disable-line no-bitwise

    hex.push((current & 0xf).toString(16)); // eslint-disable-line no-bitwise
  }
  return hex.join('');
}
