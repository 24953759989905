import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import fullUrl from 'utils/fullUrl';
import round from 'utils/round';
import logoDefault from 'assets/img/logoDefault.png';
import { fieldIpfsIcon } from '../../../redux/reducers/tokenReducer';

const headCells = [
  {
    id: 'tokenName',
    numeric: false,
    disablePadding: false,
    label: 'Token name',
  },
  {
    id: 'sellPrice',
    numeric: true,
    disablePadding: false,
    label: 'Sell price',
  },
  {
    id: 'buyPrice',
    numeric: true,
    disablePadding: false,
    label: 'Buy price',
  },
  {
    id: 'change',
    numeric: true,
    disablePadding: false,
    label: '24H%',
  },
];

const styleNameBlockImg = {
  'margin-right': '3%',
  width: 'auto',
  borderRadius: '50%',
};

const styleNameBlock = {
  display: 'flex',
  'align-items': 'center',
};

const styleNameBlockP = {
  'font-size': '14px',
  'margin-right': 'auto',
  color: 'var(--main-gray-color)',
  'margin-left': 'auto',
};

const styleNameBlockPB = {
  'font-size': '14px',
  color: 'white',
  'margin-right': 'auto',
  'margin-left': 'auto',
};

const styleCwebButton: CSSProperties = {
  background:
    'linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(11, 187, 177) 0%, rgb(6, 120, 189) 100%)',
  textAlign: 'center',
  borderRadius: '25px',
  padding: '10px 12px',
  marginTop: '6px',
  marginLeft: '10px',
  border: 'none',
  width: '133px',
  marginRight: '-2px',
  display: 'inline-block',
  color: 'var(--main-white-color)',
  fontWeight: 'var(--main-fontPlusNormal-weight)',
};

const constHashSTBL =
  '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';

const constNameSTBL = 'Stability';
const constSymbolSTBL = 'STBL';
const constExponentSTBL = 18;

const constImageSTBL =
  'bafkreier7mirvue5ae4n65uzbldniziaxsligaxfgr62oyoulygpbr2n2y';

const constMasSTBL = [
  {
    name: 'ipfs_image_url',
    content: constImageSTBL,
  },
  {
    name: 'symbol',
    content: constSymbolSTBL,
  },
  {
    name: 'name',
    content: constNameSTBL,
  },
  {
    name: 'exponent',
    content: constExponentSTBL,
  },
];
type EnhancedTableHeadProps = {
  order?: 'asc' | 'desc';
  orderBy?: string;
};
function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    // onRequestSort,
  } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              color: 'var(--main-gray-color)',
              borderBottom: 'none',
              wight: '100%',
              background: 'var(--background-color)',
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        background: 'var(--background-color)',
        borderRadius: '16px',
        marginTop: '5px',
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Chart
      </Typography>
    </Toolbar>
  );
}

type TokenBodyProps = {
  info: any;
  row: any;
};

const TokenBody = (props: TokenBodyProps) => {
  const { ipfsIcon } = useSelector((state: any) => state.tokenReducer);
  const dispatch = useDispatch();

  const [img, setImg] = useState('');
  const [name, setName] = useState('');
  const [text, setText] = useState('');

  useEffect(() => {
    (async () => {
      await props.info.forEach(async (element: any) => {
        if (element.name === 'ipfs_image_url' && img === '') {
          let imgNew = '';
          const findIcon = ipfsIcon.find(
            (el: any) => el.hash === element.content,
          );
          if (findIcon) {
            imgNew = findIcon.icon;
          } else {
            imgNew = await fullUrl(element.content);
            if (imgNew === '') imgNew = logoDefault;
            ipfsIcon.push({
              hash: element.content,
              icon: imgNew,
            });
            dispatch(fieldIpfsIcon(ipfsIcon));
          }
          setImg(imgNew);
        }
        if (element.name === 'symbol') setName(element.content);
        if (element.name === 'name') setText(element.content);
      });
    })();
  }, [dispatch, img, ipfsIcon, props.info]);

  return (
    <>
      <TableCell
        sx={{
          color: 'var(--main-white-color)',
          fontSize: '16px',
          borderBottom: 'none',
          background: 'var(--background-color)',
        }}
        align="left"
      >
        <div style={styleNameBlock}>
          {img !== '' && (
            <img
              style={styleNameBlockImg}
              src={img}
              alt=""
              width={120}
              height={40}
            />
          )}
          <div>
            <p style={styleNameBlockPB}> {name} </p>
            <p style={styleNameBlockP}> {text} </p>
          </div>
        </div>
      </TableCell>
      {props.row.liquidity ? (
        <>
          <TableCell
            sx={{
              color: 'var(--main-white-color)',
              fontSize: '16px',
              borderBottom: 'none',
              background: 'var(--background-color)',
            }}
            align="right"
          >
            {Number(props.row.liquidity.sell_price) <= 10000
              ? Number(round(Number(props.row.liquidity.sell_price)))
              : Number(round(Number(props.row.liquidity.sell_price)))}
          </TableCell>
          <TableCell
            sx={{
              color: 'var(--main-white-color)',
              fontSize: '16px',
              borderBottom: 'none',
              background: 'var(--background-color)',
            }}
            align="right"
          >
            {Number(props.row.liquidity.buy_price) <= 10000
              ? Number(round(Number(props.row.liquidity.buy_price)))
              : Number(round(Number(props.row.liquidity.buy_price)))}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell
            sx={{
              color: 'var(--main-white-color)',
              fontSize: '16px',
              borderBottom: 'none',
              background: 'var(--background-color)',
            }}
            align="right"
          />
          <TableCell
            sx={{
              color: 'var(--main-white-color)',
              fontSize: '16px',
              borderBottom: 'none',
              background: 'var(--background-color)',
            }}
            align="right"
          />
        </>
      )}
    </>
  );
};

type TokenPriceProps = {
  oldPrice: any;
  newPrice: any;
  index: any;
  info: any;
};
const TokenPrice = (props: TokenPriceProps) => {
  let oldP = 0;
  let percent = 0;
  let change = 0;
  let exponent = 0;
  props.info.forEach((element: any) => {
    if (element.name === 'exponent') exponent = Number(element.content);
  });
  if (props.oldPrice[props.index]) {
    try {
      const index = 1;
      oldP = Number(
        (
          Number(
            props.oldPrice[props.index].TokenHistoricalPriceResult.Ok[0][index],
          ) *
          10 ** exponent
        ).toFixed(2),
      );
    } catch {
      oldP = 0;
    }
  }
  if (oldP > 0) {
    change = props.newPrice - oldP;
    percent = (change / oldP) * 100;
  }
  return (
    <TableCell
      sx={{
        color: 'var(--main-white-color)',
        fontSize: '16px',
        borderBottom: 'none',
      }}
      align="right"
    >
      {change > 0 ? (
        <p style={{ color: 'green' }}>+{Number(round(percent))}%</p>
      ) : (
        <p style={{ color: 'red' }}>{Number(round(percent))}%</p>
      )}
    </TableCell>
  );
};

const TableTokenBody = () => {
  const { tokenTable, infoTokenAll, infoTokenHistoricalPriceAll } = useSelector(
    (state: any) => state.tokenReducer,
  );

  const navigate = useNavigate();

  const handleClick = (index: number) => {
    navigate(`${index + 1}`);
  };

  return (
    <Box sx={{ width: '100%', backgroundColor: 'trasnparent !important' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          backgroundColor: 'var(--background-color) !important',
          boxShadow: ' none',
        }}
      >
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            sx={{
              minWidth: 'auto',
              background: 'var(--background-color-modal-2)',
            }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead />
            <TableBody>
              {tokenTable.length > 0 &&
                tokenTable[0].TokenFilterResult.map(
                  (row: any, index: number) => {
                    return (
                      <TableRow
                        onClick={() => handleClick(index)}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                        sx={{
                          cursor: 'pointer',
                          background: 'var(--background-color)',
                        }}
                      >
                        {row.token === constHashSTBL ? (
                          <TokenBody info={constMasSTBL} row={row} />
                        ) : (
                          <TokenBody
                            info={
                              infoTokenAll[index].TokenQueryResult
                                .LastExtraFields.Ok
                            }
                            row={row}
                          />
                        )}
                        {row.liquidity && (
                          <TokenPrice
                            oldPrice={infoTokenHistoricalPriceAll}
                            newPrice={row.liquidity.sell_price}
                            index={index}
                            info={
                              infoTokenAll[index].TokenQueryResult
                                .LastExtraFields.Ok
                            }
                          />
                        )}
                      </TableRow>
                    );
                  },
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <button style={styleCwebButton} onClick={() => handleClick(-1)}>
          See CWEB
        </button>
      </Paper>
    </Box>
  );
};
// eslint-disable-next-line
export default TableTokenBody;
