import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import styles from './styles.module.scss';
import calculateSentEmbed from '../calculateSentEmbed';
import { walletWithKey } from '../useWallet';
import iconCross from '../../assets/img/cross.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

type ModalMnemonicProps = {
  open: boolean;
  handleClose: () => void;
};

const ModalMnemonic = (props: ModalMnemonicProps) => {
  const [mnemonicPhrase, setMnemonicPhrase] = useState('');

  const {
    fieldTokenSell,
    fieldTokenBuy,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    priceType,
    slipPage,
  } = useSelector((state: any) => state.tokenReducer);

  const handlerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMnemonicPhrase(e.target.value);
  };

  const handlerPay = async () => {
    const wallet = await walletWithKey(mnemonicPhrase);
    calculateSentEmbed(
      fieldTokenSell * 10 ** fieldTokenSellChoose.exponent,
      fieldTokenBuy * 10 ** fieldTokenBuyChoose.exponent,
      fieldTokenSellChoose.hash,
      fieldTokenBuyChoose.hash,
      priceType,
      slipPage,
      wallet,
    );
    props.handleClose();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <h4>SWAPPING</h4>
            <div onClick={props.handleClose}>
              <img src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>Introduce a mnenmonical phrase</p>
            </div>
          </div>
          <div className={styles.inputBlock}>
            <input
              type="text"
              onChange={handlerInput}
              placeholder="similar library stage coil transfer evil family note evidence fine toe quiz"
            />
          </div>
          <div className={styles.greenBlock} onClick={handlerPay}>
            <div className={styles.greenText}>
              <p>Pay</p>
            </div>
          </div>
          <div className={styles.redBlock} onClick={props.handleClose}>
            <div className={styles.redText}>
              <p>Dismiss</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalMnemonic;
