/* global BigInt */
import intToHexString from 'utils/intToHexString';

export default function calculateQr(
  sellPrice,
  buyPrice,
  buyToken,
  sellToken,
  caclutateType,
  slipPage,
) {
  try {
    if (
      !slipPage ||
      buyPrice === 0 ||
      sellPrice === 0 ||
      (buyToken === undefined && sellToken === undefined)
    )
      return ``;
    let jsonQr;
    if (caclutateType === 'sell' && slipPage) {
      if (buyToken === undefined) {
        jsonQr = `{"TokenV1":{"BuyViaCwebUi":{"token":"${sellToken}","cweb_amount":"${intToHexString(
          sellPrice,
          0,
        )}","limit_token_amount":"${intToHexString(
          buyPrice * (1 - slipPage),
          0,
        )}"}}}`;
      }
      if (sellToken === undefined) {
        jsonQr = `{"TokenV1":{"SellTokenUi":{"token":"${buyToken}","token_amount":"${intToHexString(
          sellPrice,
          0,
        )}","limit_cweb_amount":"${intToHexString(
          buyPrice * (1 - slipPage),
          0,
        )}"}}}`;
      }
      if (sellToken && buyToken) {
        jsonQr = `{"TokenV1":{"SellForTokenUi":{"token_a":"${buyToken}","token_b":"${sellToken}","token_a_amount":"${intToHexString(
          sellPrice,
          0,
        )}","limit_token_b_amount":"${intToHexString(
          buyPrice * (1 - slipPage),
          0,
        )}"}}}`;
      }
    } else if (slipPage) {
    } else if (slipPage) {
      if (buyToken === undefined) {
        jsonQr = `{"TokenV1":{"BuyTokenUi":{"token":"${sellToken}","token_amount":"${intToHexString(
          buyPrice,
          0,
        )}","limit_cweb_amount":"${intToHexString(
          sellPrice * (1 + slipPage),
          0,
        )}"}}}`;
      }
      if (sellToken === undefined) {
        jsonQr = `{"TokenV1":{"SellViaCwebUi":{"token":"${buyToken}","cweb_amount":"${intToHexString(
          buyPrice,
          0,
        )}","limit_token_amount":"${intToHexString(
          sellPrice * (1 + slipPage),
          0,
        )}"}}}`;
      }
      if (sellToken && buyToken) {
        jsonQr = `{"TokenV1":{"BuyForTokenUi":{"token_a":"${buyToken}","token_b":"${sellToken}","token_b_amount":"${intToHexString(
          buyPrice,
          0,
        )}","limit_token_a_amount":"${intToHexString(
          sellPrice * (1 + slipPage),
          0,
        )}"}}}`;
      }
    }
    return jsonQr;
  } catch {
    return '';
  }
}
