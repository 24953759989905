import webapp from '@coinweb/webapp-library';
import createGlobalState from './factory/createGlobalState';

const podWithKey = (pubKey) => {
  const subscription = webapp.subscribe_webapp(pubKey, undefined, {
    keepAlive: 10_000,
    retryAttempts: 100,
    shouldRetry: (error) => {
      // eslint-disable-next-line no-console
      console.error('WS error:', error);
      return true;
    },
    onNonLazyError: (errorOrCloseEvent) => {
      // eslint-disable-next-line no-console
      console.error('WS error or close event:', errorOrCloseEvent);
    },
    on: {
      error: (received) => {
        // eslint-disable-next-line no-console
        console.error('WS `error`:', received);
      },
      closed: (received) => {
        // eslint-disable-next-line no-console
        console.warn('WS `closed`:', received);
      },
    },
  });

  return subscription;
};

const usePodValue = createGlobalState({ pubKey: '' });

export const usePod = () => {
  const [wrapper, setWrapper] = usePodValue();
  return [
    wrapper.pod,
    async (pubKey, privKey) => {
      let isActual = true;
      setWrapper((oldWrapper) => {
        isActual = oldWrapper.pubKey !== pubKey;
        return oldWrapper;
      });
      if (!isActual) {
        return;
      }
      const newPod = podWithKey(pubKey);
      setWrapper({ pubKey, privKey, pod: newPod });
    },
  ];
};
