import {
  ApolloClient,
  gql,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client/core';
import { getMainDefinition } from '@apollo/client/utilities';
import { REACT_APP_WHITELIST_API_ENDPOINT } from '../../conf';

const GET_WHITELIST = gql`
  query {
    getTokenWhitelist
  }
`;

export async function getWhitelistToken() {
  const httpLink = new HttpLink({
    uri: REACT_APP_WHITELIST_API_ENDPOINT,
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    httpLink,
    httpLink,
  );

  // eslint-disable-next-line
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({ resultCacheMaxSize: 100 }),
  });

  const wsOptions = {
    query: GET_WHITELIST,
  };

  const query = await client.query(wsOptions);
  return query.data.getTokenWhitelist;
}
