import { gql } from '@apollo/client';

export const CWEB_HISTORY = gql`
  query PricesInterface($days: Int!) {
    getHistoryPricesForCwebInPeriods(
      requestedNumHistoryDays: $days
      requestedFiat: USD
    ) {
      histCwebPricesInFiatInIntervals {
        intervalTimestampUtc
        cryptoPriceInFiat
      }
    }
  }
`;

// export function getCwebHistory(days) {
//   // eslint-disable-next-line
//   return useQuery(CWEB_HISTORY, { variables: { days } });
// }
