import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wallet, TrackError } from '@coinweb/cweb-wallet-library';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import calculatePrice from 'utils/calculatePrice';
import round from 'utils/round';
import fullUrl from 'utils/fullUrl';
import logoDefault from 'assets/img/logoDefault.png';
import handleErrorMessage from 'utils/handleErrorMessage';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './MyInput.module.css';
import {
  fieldPriceType,
  fieldSwapResult,
  fieldPriceImpact,
  fieldNetworkFee,
  fieldSwapResultErrorMessage,
  fieldIpfsIcon,
} from '../../redux/reducers/tokenReducer';

type MyInputProps = {
  onClickBtn: any;
  textBtn: any;
  value: any;
  onChange: any;
  onChangeOther: any;
  inputDisabled: boolean;
  calType: 'sell' | 'buy';
  wallet: Wallet | undefined;
};

type masHandlePriceProps = {
  price: {
    networkFee: string | number;
    priceImpact: string | number;
    tokenAmount: string | number;
    err: undefined | TrackError | string;
  };
  nowDate: number;
  used: boolean;
};

const MyInput = (props: MyInputProps) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);
  const [calculationError, setCalculationError] = useState(false);

  const [previousValue, setPreviousValue] = useState<any>(props.value);

  const [nowImage, setNowImage] = useState<string | undefined>('');

  const [masHandlePrice, setMasHandlePrice] = useState<masHandlePriceProps[]>(
    [],
  );

  const {
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    isLogin,
    ipfsIcon,
    maxBuyExchangeAmount,
  } = useSelector((state: any) => state.tokenReducer);

  const balance = round(
    Number(props.textBtn.balance) / 10 ** props.textBtn.exponent,
  );

  const handlePrice = useCallback(
    async (val: number) => {
      const nowDate = new Date().getTime();
      let price: {
        networkFee: string | number;
        priceImpact: string | number;
        tokenAmount: string | number;
        err: undefined | TrackError | string;
      } = {
        networkFee: '0',
        priceImpact: '0',
        tokenAmount: '0',
        err: undefined,
      };
      try {
        price = await calculatePrice(
          props.wallet,
          val * 10 ** fieldTokenSellChoose.exponent,
          fieldTokenSellChoose.hash,
          fieldTokenBuyChoose.hash,
          props.calType,
          fieldTokenBuyChoose.exponent,
        );
        masHandlePrice.push({
          price,
          nowDate,
          used: false,
        });
        setMasHandlePrice([...masHandlePrice]);
      } catch {
        return {
          networkFee: '0',
          priceImpact: '0',
          tokenAmount: '0',
          err: 'Connection error. Please try again later.',
        };
      }
      return price;
    },
    [
      fieldTokenBuyChoose.exponent,
      fieldTokenSellChoose.exponent,
      fieldTokenSellChoose.hash,
      masHandlePrice,
      props.calType,
      props.wallet,
    ],
  );
  useEffect(() => {
    if (masHandlePrice.length > 0) {
      try {
        const elHandlePrice = masHandlePrice.reduce((acc, curr) =>
          acc.nowDate > curr.nowDate ? acc : curr,
        );
        if (elHandlePrice.used === false) {
          elHandlePrice.used = true;
          if (elHandlePrice.price.err) {
            dispatch(fieldSwapResult(false));
            dispatch(
              fieldSwapResultErrorMessage(
                handleErrorMessage(elHandlePrice.price.err),
              ),
            );
          }

          dispatch(props.onChangeOther(elHandlePrice.price.tokenAmount));
          dispatch(fieldPriceImpact(elHandlePrice.price.priceImpact));
          dispatch(fieldNetworkFee(elHandlePrice.price.networkFee));
          if (
            Number(elHandlePrice.price.tokenAmount) > 0 &&
            !elHandlePrice.price.err
          )
            dispatch(fieldSwapResult(true));
          setMasHandlePrice([{ ...elHandlePrice }]);
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error, 'error');
      }
    }
  }, [masHandlePrice, dispatch, props]);

  useEffect(() => {
    const NumValue = parseFloat(props.value);
    const numBalance = parseFloat(balance);
    if (props.calType === 'sell') {
      if (NumValue > 0 && NumValue < numBalance) {
        setMinError(false);
        setMaxError(false);
      } else if (NumValue === numBalance) {
        const timeoutId = setTimeout(() => setMaxError(false), 1000); // 2 seconds

        return () => clearTimeout(timeoutId); // Cleanup function to clear timeout
      } else if (NumValue < 0) {
        dispatch(props.onChange(0));
        setMinError(true);
        setMaxError(false);
      } else if (NumValue > numBalance) {
        dispatch(props.onChange(numBalance));
        handlePrice(numBalance);
        setMinError(false);
        setMaxError(true);
      }
    } else {
      setCalculationError(
        maxBuyExchangeAmount && NumValue > parseFloat(maxBuyExchangeAmount),
      );
    }
    setPreviousValue(props.value);
    return undefined;
  }, [maxBuyExchangeAmount, balance, handlePrice, props, dispatch]);

  useEffect(() => {
    (async () => {
      try {
        if (nowImage) setNowImage(undefined);
        if (props.textBtn.img !== '') {
          setNowImage(props.textBtn.img);
        } else if (props.textBtn.imgH && props.textBtn.imgH !== '') {
          let tmpImage;
          const findIcon = ipfsIcon.find(
            (el: any) => el.hash === props.textBtn.imgH,
          );
          if (findIcon) {
            tmpImage = findIcon.icon;
          } else {
            tmpImage = await fullUrl(props.textBtn.imgH);
            if (tmpImage === '') tmpImage = logoDefault;
            ipfsIcon.push({
              hash: props.textBtn.imgH,
              icon: tmpImage,
            });
            dispatch(fieldIpfsIcon(ipfsIcon));
          }
          setNowImage(tmpImage);
        } else {
          setNowImage(logoDefault);
        }
      } catch {
        setNowImage(logoDefault);
      }
    })();
  }, [props.textBtn]);
  const hasExcessiveZeros = (value: string) => {
    // Check if the value contains more than one leading zero
    return /^0{2,}/.test(value);
  };

  const handlerInput = async (e: any) => {
    let targetValue = e.target.value;
    targetValue = targetValue.replace(/,/g, '.');
    targetValue = targetValue.replace(/e/g, '');
    const numericValue = parseFloat(targetValue);
    if (!Number.isNaN(numericValue) && !hasExcessiveZeros(targetValue)) {
      if (props.calType === 'sell') {
        if (targetValue < 0) {
          setMinError(true);
          setMaxError(false);
          targetValue = 0;
        } else if (targetValue > Number(balance)) {
          targetValue = Number(balance);
          setMinError(false);
          setMaxError(true);
        } else {
          setMinError(false);
          setMaxError(false);
        }
      }

      dispatch(props.onChange(targetValue));
      setPreviousValue(targetValue);

      if (
        fieldTokenBuyChoose.id === undefined ||
        fieldTokenSellChoose.id === undefined
      ) {
        return;
      }
      try {
        handlePrice(targetValue);
      } catch (err) {
        dispatch(
          fieldSwapResultErrorMessage(
            'Connection error. Please try again later',
          ),
        );
        dispatch(props.onChangeOther('0'));
        dispatch(fieldPriceImpact('0'));
        dispatch(fieldNetworkFee('0'));
        dispatch(fieldSwapResult(false));
      }
      dispatch(fieldPriceType(props.calType));
    } else {
      dispatch(props.onChange(previousValue));
    }
  };

  return (
    <div id={styles.input_wrapper}>
      <div
        className={`${active ? styles.containerActive : styles.container}`}
        onClick={() => setActive(true)}
      >
        <div className={styles.blockInput}>
          <input
            min="0"
            pattern="^[0-9]*[.,]?[0-9]*$"
            type="number"
            disabled={props.inputDisabled}
            className={styles.input}
            value={props.value}
            onChange={handlerInput}
            placeholder="0"
            max={balance}
            maxLength={15}
            onKeyDown={(e: any) => {
              if (
                (e.key === 'Backspace' || e.keyCode === 8) &&
                e.target?.value?.length === 1
              ) {
                dispatch(props.onChange(NaN));
              }
            }}
          />
        </div>
        {isLogin &&
          (props.textBtn === undefined ||
          Object.entries(props.textBtn).length === 0 ? (
            <button
              disabled={props.inputDisabled}
              className={`${styles.btnNotToken}`}
              onClick={props.onClickBtn}
            >
              <span>Choose a token</span>
              <KeyboardArrowDownIcon />
            </button>
          ) : (
            <div>
              <button
                disabled={props.inputDisabled}
                className={`${styles.btn} `}
                onClick={props.onClickBtn}
              >
                <div className={styles.contentBtn}>
                  {nowImage !== '' ? (
                    <>
                      {nowImage ? (
                        <img
                          alt="iconLogo"
                          src={nowImage}
                          className={styles.headersblockImg}
                        />
                      ) : (
                        <div className={styles.headersblockImg}>
                          <CircularProgress size={25} />
                        </div>
                      )}
                      <span className={styles.headersblockText}>
                        {props.textBtn.name}
                      </span>
                    </>
                  ) : (
                    <span className={styles.headersblockTextImg}>
                      {props.textBtn.name}
                    </span>
                  )}
                  <KeyboardArrowDownIcon />
                </div>
              </button>
              <div className={styles.balance}>Balance: {balance}</div>
            </div>
          ))}
      </div>
      <div>
        {minError && props.calType === 'sell' && (
          <p className={styles.errorText}>
            {fieldTokenSellChoose.name} can not be less than 0.
          </p>
        )}
        {(maxError || calculationError) && props.calType === 'sell' && (
          <p className={styles.errorText}>
            {fieldTokenSellChoose.name} can not exceed the balance.
          </p>
        )}
      </div>
    </div>
  );
};

export default MyInput;
