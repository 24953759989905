import {
  create_wallet as createWallet,
  subscribe_to_notifications as subscribeToNotifications,
  Wallet,
  wasmLoadingPromise,
} from '@coinweb/cweb-wallet-library';
import { REACT_APP_API_ENDPOINT } from '../conf';
import createGlobalState from './factory/createGlobalState';

const walletWithKey = async (pubKey: string): Promise<Wallet> => {
  const address = `${REACT_APP_API_ENDPOINT}/wallet`;
  const wsAddress = address.replace(/^https:/, 'wss:');
  // const address =
  //  'https://29112346-review.dev6.coinz.team/review-adp-price-v9exsk/wallet';
  // 'https://coinweb-experimental-bitcoin-graphql-demogreen.dev6.coinz.team/wallet';

  // const wsAddress =
  //  'wss://29112346-review.dev6.coinz.team/review-adp-price-v9exsk/wallet';
  // 'wss://coinweb-experimental-bitcoin-graphql-staging.dev6.coinz.team/wallet';

  if (wasmLoadingPromise !== undefined) {
    await wasmLoadingPromise;
  }
  // enableLogging(); // For debugging
  const wallet = await createWallet({
    address,
    ws_address: wsAddress,
    pub_key: pubKey,
    max_retry_time_secs: 300,
    shard: null,
    sign_callback: (_msg: Uint8Array) => {
      throw new Error('Unimplemented');
    },
    enable_retries: true,
  });

  const subscription = subscribeToNotifications(wallet, undefined, {
    keepAlive: 10_000,
    retryAttempts: 100,
    shouldRetry: (error) => {
      // eslint-disable-next-line no-console
      console.error('WS error:', error);
      return true;
    },
    onNonLazyError: (errorOrCloseEvent) => {
      // eslint-disable-next-line no-console
      console.error('WS error or close event:', errorOrCloseEvent);
    },
    on: {
      error: (received) => {
        // eslint-disable-next-line no-console
        console.error('WS `error`:', received);
      },
      closed: (received) => {
        // eslint-disable-next-line no-console
        console.warn('WS `closed`:', received);
      },
    },
  });
  // NOTE: call to `subscribe` is needed, even if supplied lambda
  // will do nothing
  subscription.subscribe((x) => {});
  return wallet;
};

type WalletWrapper = {
  wallet?: Wallet;
  pubKey: String;
};

const useWalletValue = createGlobalState<WalletWrapper>({ pubKey: '' });

type ReinitializeWalletFn = (pubKey: string) => Promise<void>;

type UseWallet = () => [Wallet | undefined, ReinitializeWalletFn];

function freeWallet(wallet?: Wallet) {
  if (wallet != null) {
    wallet.free();
  }
}

export const useWallet: UseWallet = () => {
  const [wrapper, setWrapper] = useWalletValue();
  return [
    wrapper!.wallet,
    async (pubKey: string) => {
      let isActual = true;
      setWrapper((oldWrapper) => {
        isActual = oldWrapper.pubKey !== pubKey;
        if (isActual) {
          freeWallet(oldWrapper.wallet);
          return { pubKey };
        }
        return oldWrapper;
      });
      if (!isActual) {
        return;
      }
      const newWallet = await walletWithKey(pubKey);
      setWrapper({ pubKey, wallet: newWallet });
    },
  ];
};
