import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import BottomCoinwebContainer from 'components/Containers/BottomCoinwebContainer';
import createChartData from 'utils/createChartData';
import calculateChartDate from 'utils/calculateChartDate';
import { query_tokenization_block as queryTokenizationBlock } from '@coinweb/cweb-wallet-library';
import { useWallet } from 'hooks/useWallet';
import { useParams, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
// import iconDownload from '../../assets/img/download.svg';
import iconArrow from '../../assets/img/arrowDown.svg';
// import iconOkay from '../../assets/img/superOkay.svg';
// import iconArrowRed from '../../assets/img/arrowRed.svg';
// import StatsToken from '../../components/StatsToken/StatsToken';
import styles from './TokenAbout.module.css';
import { LineChart } from '../../components/LineChart';
import { CWEB_HISTORY } from '../../components/queries/cwebHistory';

const styleNameBlockImg = {
  marginRight: '3%',
  width: 'auto',
  borderRadius: '50%',
};

const styleNameBlock = {
  display: 'flex',
  alignItems: 'center',
  width: '200px',
};

const styleNameBlockP = {
  fontSize: '14px',
  color: 'var(--main-gray-color)',
  marginRight: 'auto',
  marginLeft: 'auto',
};

const styleDataErrorP = {
  fontSize: '16px',
  color: '##c9634d',
  margin: '20px 0px',
  width: '100%',
  textAlign: 'center',
};

const styleNameBlockPB = {
  fontSize: '14px',
  marginRight: 'auto',
  color: 'white',
  marginLeft: 'auto',
};

const TokenAbout = () => {
  const [countDate, setCountDate] = useState(1);
  const [wallet] = useWallet();
  const { id } = useParams();
  const navigate = useNavigate();
  const [ourData, setOurData] = useState({});
  const [chartData, setChartData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  const [activeTab, setActiveTab] = useState('1');

  const { infoTokenAll, tokenTable, tokenSweb, infoTokenHistoricalPriceAll } =
    useSelector((state) => state.tokenReducer);

  const {
    loading,
    data: dataResult,
    refetch,
  } = useQuery(CWEB_HISTORY, {
    variables: {
      days: countDate,
    },
    skip: Number(id) !== 0, // Skip the query if Number(id) is not equal to 0
  });
  useEffect(() => {
    // Check if infoTokenHistoricalPriceAll has data
    if (!infoTokenHistoricalPriceAll) {
      setIsLoading(true); // Set loading to true if there's no data
    } else {
      setIsLoading(false); // Set loading to false if data is available
    }
  }, [infoTokenHistoricalPriceAll]);

  useEffect(() => {
    if (Number(id) === 0) {
      // Re-fetch the data whenever countDate changes
      refetch();
    }
  }, [id, countDate, refetch]);

  useEffect(() => {
    setIsLoading(true);
    let oldPrice = 1;
    try {
      if (Number(id) === 0 && !loading && dataResult) {
        // cweb
        let cwebData = [];
        dataResult.getHistoryPricesForCwebInPeriods.histCwebPricesInFiatInIntervals.forEach(
          (token) => {
            cwebData = [
              {
                primary: token.intervalTimestampUtc,
                secondary: token.cryptoPriceInFiat,
              },
            ].concat(cwebData);
          },
        );

        if (cwebData.length > 0) {
          oldPrice = cwebData[0].secondary;
        }

        setChartData(cwebData);
        setOurData({ ...tokenSweb, oldPrice });
        setIsLoading(false);
      } else {
        (async () => {
          let img = '';
          let name = '';
          let text = '';
          let exponent = 0;
          const constHashSTBL =
            '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';

          if (
            tokenTable.length <= 0 ||
            tokenTable[0].TokenFilterResult[id - 1].token === constHashSTBL
          ) {
            // if token is stable
            const constNameSTBL = 'Stability';
            const constSymbolSTBL = 'STBL';
            const constExponentSTBL = 18;

            const constImageSTBL =
              'bafkreier7mirvue5ae4n65uzbldniziaxsligaxfgr62oyoulygpbr2n2y';

            img = constImageSTBL;
            name = constSymbolSTBL;
            text = constNameSTBL;
            exponent = constExponentSTBL;
          } else {
            infoTokenAll[id - 1].TokenQueryResult.LastExtraFields.Ok.forEach(
              (element) => {
                if (element.name === 'ipfs_image_url') img = element.content;
                if (element.name === 'symbol') name = element.content;
                if (element.name === 'name') text = element.content;
                if (element.name === 'exponent')
                  exponent = Number(element.content);
              },
            );
          }

          if (countDate === 1 && infoTokenHistoricalPriceAll.length >= id) {
            const lineChartData = createChartData(
              infoTokenHistoricalPriceAll[id - 1].TokenHistoricalPriceResult.Ok,
              exponent,
            );

            setChartData(lineChartData.data);
            setOurData({
              img: '',
              imgH: img,
              name,
              text,
              exponent,
              oldPrice: lineChartData.oldprice,
            });
            setIsLoading(false);
          } else {
            const time = calculateChartDate(countDate);

            const tokenHistoricalPrice = [
              {
                TokenHistoricalPriceQuery: {
                  token:
                    tokenTable.length > 0
                      ? tokenTable[0].TokenFilterResult[id - 1].token
                      : constHashSTBL,
                  start: time.start,
                  finish: time.finish,
                },
              },
            ];
            try {
              const tokensHistoricalPriceAll = await queryTokenizationBlock(
                wallet,
                tokenHistoricalPrice,
              );

              const lineChartData = createChartData(
                tokensHistoricalPriceAll[0].TokenHistoricalPriceResult.Ok,
                exponent,
              );

              if (lineChartData.oldprice <= 0) lineChartData.oldprice = 0.01;
              setChartData(lineChartData.data);

              setOurData({
                img,
                name,
                text,
                exponent,
                oldPrice: lineChartData.oldprice,
              });
              setIsLoading(false);
            } catch (err) {
              setOurData({ text: '', name: '', img: '', testData: [] });
            }
          }
        })();
      }
    } catch (err) {
      setOurData({ text: '', name: '', img: '', testData: [] });
    }
  }, [
    id,
    countDate,
    loading,
    tokenSweb,
    dataResult,
    infoTokenAll,
    tokenTable,
    infoTokenHistoricalPriceAll,
    wallet,
  ]);

  const handleChartComponent = () => {
    if (loading || isLoading) {
      // Show loading indicator
      return (
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress
            sx={{ color: '#fff', margin: '28px auto' }}
            size={160}
          />
        </div>
      );
    }
    if (chartData.length > 0) {
      // Show LineChart when data is available
      return (
        <LineChart
          width={780}
          widthXs={300}
          widthSm={580}
          widthMd={780}
          widthLg={780}
          widthXl={780}
          height={450}
          yTokenName=""
          oldPrice={ourData.oldPrice}
          data={chartData}
        />
      );
    }
    // Show "Data Not Found" message
    return <p style={styleDataErrorP}>Data Not Found</p>;
  };
  return (
    <div style={{ minHeight: '100vh' }}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div>
            <div className={styles.blockBack} onClick={() => navigate(-1)}>
              <img src={iconArrow} alt="" />
              <p>Tokens</p>
            </div>
            <div className={styles.blockData}>
              <div className={styles.blockDataTitle}>
                <div style={styleNameBlock}>
                  {ourData.img !== '' && (
                    <img
                      style={styleNameBlockImg}
                      src={ourData.img}
                      alt=""
                      width={120}
                      height={40}
                    />
                  )}
                  <div>
                    <p style={styleNameBlockPB}> {ourData.name} </p>
                    <p style={styleNameBlockP}> {ourData.text} </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.blockDataDiagram}>
              {handleChartComponent()}
            </div>

            {chartData.length > 0 && (
              <div className={styles.blockDataTabs}>
                <div className={styles.blockDataTabsItem}>
                  <div
                    className={
                      activeTab === '1'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('1');
                      setCountDate(1);
                    }}
                  >
                    24H
                  </div>
                  <div
                    className={
                      activeTab === '2'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('2');
                      setCountDate(7);
                    }}
                  >
                    7D
                  </div>
                  <div
                    className={
                      activeTab === '3'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('3');
                      setCountDate(14);
                    }}
                  >
                    2W
                  </div>
                  <div
                    className={
                      activeTab === '4'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('4');
                      setCountDate(31);
                    }}
                  >
                    1M
                  </div>
                  <div
                    className={
                      activeTab === '5'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('5');
                      setCountDate(93);
                    }}
                  >
                    3M
                  </div>
                  <div
                    className={
                      activeTab === '6'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('6');
                      setCountDate(126);
                    }}
                  >
                    6M
                  </div>
                  <div
                    className={
                      activeTab === '7'
                        ? styles.tabsItemActive
                        : styles.tabsItem
                    }
                    onClick={() => {
                      setActiveTab('7');
                      setCountDate(366);
                    }}
                  >
                    1Y
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <BottomCoinwebContainer />
    </div>
  );
};
export default TokenAbout;
