import iconCweb from '../../assets/img/iconCweb.svg';
export const FIELD_PUB_TOKEN = 'FIELD_PUB_TOKEN';
export const TOKEN_DATA_TABLE = 'TOKEN_DATA_TABLE';
export const FIELD_WINDOW_ADDRESS = 'FIELD_WINDOW_ADDRESS';
export const FIELD_WINDOW_TOKEN = 'FIELD_WINDOW_TOKEN';
export const FIELD_WINDOW_ADDRESS_CHOOSE = 'FIELD_WINDOW_ADDRESS_CHOOSE';
export const FIELD_WINDOW_TOKEN_CHOOSE = 'FIELD_WINDOW_TOKEN_CHOOSE';
export const INFO_TOKEN = 'INFO_TOKEN';
export const INFO_TOKEN_PRICE = 'INFO_TOKEN_PRICE';
export const INFO_TOKEN_BALANCE = 'INFO_TOKEN_BALANCE';
export const CONFIRM_SWAP = 'CONFIRM_SWAP';
export const TOKEN_PRICE = 'TOKEN_PRICE';
export const IS_LOGIN = 'IS_LOGIN';
export const BALANCE_SWEP = 'BALANCE_SWEP';
export const PRICE_SWEB = 'PRICE_SWEB';
export const LOGOUT = 'LOGOUT';
export const INFO_TOKEN_PRICE_ALL = 'INFO_TOKEN_PRICE_ALL';
export const INFO_TOKEN_BALANCE_ALL = 'INFO_TOKEN_BALANCE_ALL';
export const INFO_TOKEN_ALL = 'INFO_TOKEN_ALL';
export const PRICE_TYPE = 'PRICE_TYPE';
export const SWAP_RESULT = 'SWAP_RESULT';
export const SWAP_RESULT_ERROR_MESSAGE = 'SWAP_RESULT_ERROR_MESSAGE';
export const SLIP_PAGE = 'SLIP_PAGE';
export const INFO_TOKEN_HISTORICAL_PRICE_ALL =
  'INFO_TOKEN_HISTORICAL_PRICE_ALL';
export const PRICE_IMPACT = 'PRICE_IMPACT';
export const NETWORK_FEE = 'NETWORK_FEE';
export const SUBSCRIBED_TOKEN = 'SUBSCRIBED_TOKEN';
export const WHITELISTED_TOKEN = 'WHITELISTED_TOKEN';
export const INFO_SUBSCRIBED_TOKEN = 'INFO_SUBSCRIBED_TOKEN';
export const INFO_WHITELISTED_TOKEN = 'INFO_WHITELISTED_TOKEN';
export const INFO_BALANCE_SUBSCRIBED_TOKEN = 'INFO_BALANCE_SUBSCRIBED_TOKEN';
export const INFO_PRICE_WHITELISTED_TOKEN = 'INFO_PRICE_WHITELISTED_TOKEN';
export const INFO_PRICE_SUBSCRIBED_TOKEN = 'INFO_PRICE_SUBSCRIBED_TOKEN';
export const INFO_BALANCE_WHITELISTED_TOKEN = 'INFO_BALANCE_WHITELISTED_TOKEN';
export const IPFS_ICON = 'IPFS_ICON';
export const MAX_BUY_EXCHANGE_AMOUNT = 'MAX_BUY_EXCHANGE_AMOUNT';
export const UNIT_TOKEN_PRICE = 'UNIT_TOKEN_PRICE';

const initialState = {
  tokenSweb: {
    id: undefined,
    name: 'Cweb',
    img: iconCweb,
    text: 'CWEB',
    exponent: 18,
  },
  balanceSwep: 0,
  priceSweb: 0,
  networkFee: 0,
  priceImpact: 0,
  priceSweb: 0,
  tokenTable: [],
  fieldTokenSell: 0,
  fieldTokenBuy: 0,
  fieldTokenSellChoose: {},
  fieldTokenBuyChoose: {},
  savePubKey: [],
  infoToken: [],
  infoTokenPrice: [],
  infoTokenBalance: [],
  infoTokenAll: [],
  infoTokenPriceAll: [],
  infoTokenBalanceAll: [],
  infoTokenHistoricalPriceAll: [],
  confirmSwap: false,
  tokenPrice: 0,
  isLogin: false,
  priceType: '',
  swapResult: false,
  swapResultErrorMessage: '',
  slipPage: 0.05,
  subscribedToken: [],
  whitelistedToken: [],
  infoTokenSubscribed: [],
  infoTokenPriceSubscribed: [],
  infoTokenBalanceSubscribed: [],
  infoTokenWhitelisted: [],
  infoTokenPriceWhitelisted: [],
  infoTokenBalanceWhitelisted: [],
  ipfsIcon: [],
  maxBuyExchangeAmount: undefined,
  unitTokenPrice: 0,
};

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOKEN_DATA_TABLE:
      return {
        ...state,
        tokenTable: action.tokenTable,
      };
    case FIELD_WINDOW_ADDRESS:
      if (action.fieldTokenBuy) {
        return {
          ...state,
          fieldTokenBuy: action.fieldTokenBuy,
          fieldTokenSell: action.fieldTokenSell,
        };
      }
      return {
        ...state,
        fieldTokenSell: action.fieldTokenSell,
      };
    case FIELD_WINDOW_TOKEN:
      if (action.fieldTokenSell) {
        return {
          ...state,
          fieldTokenBuy: action.fieldTokenBuy,
          fieldTokenSell: action.fieldTokenSell,
        };
      }
      return {
        ...state,
        fieldTokenBuy: action.fieldTokenBuy,
      };
    case FIELD_WINDOW_ADDRESS_CHOOSE:
      return {
        ...state,
        fieldTokenSellChoose: action.fieldTokenSellChoose,
      };
    case FIELD_WINDOW_TOKEN_CHOOSE:
      return {
        ...state,
        fieldTokenBuyChoose: action.fieldTokenBuyChoose,
      };
    case FIELD_PUB_TOKEN:
      return {
        ...state,
        savePubKey: action.savePubKey,
      };
    case INFO_TOKEN:
      return {
        ...state,
        infoToken: action.infoToken,
      };
    case INFO_TOKEN_PRICE:
      return {
        ...state,
        infoTokenPrice: action.infoTokenPrice,
      };
    case INFO_TOKEN_BALANCE:
      return {
        ...state,
        infoTokenBalance: action.infoTokenBalance,
      };
    case CONFIRM_SWAP:
      return {
        ...state,
        confirmSwap: action.confirmSwap,
      };
    case TOKEN_PRICE:
      return {
        ...state,
        tokenPrice: action.tokenPrice,
      };
    case IS_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      };
    case BALANCE_SWEP:
      return {
        ...state,
        balanceSwep: action.balanceSwep,
      };
    case PRICE_SWEB:
      return {
        ...state,
        isLogin: action.priceSweb,
      };
    case LOGOUT:
      return initialState;
    case INFO_TOKEN_ALL:
      return {
        ...state,
        infoTokenAll: action.infoTokenAll,
      };
    case INFO_TOKEN_BALANCE_ALL:
      return {
        ...state,
        infoTokenBalanceAll: action.infoTokenBalanceAll,
      };
    case INFO_TOKEN_PRICE_ALL:
      return {
        ...state,
        infoTokenPriceAll: action.infoTokenPriceAll,
      };
    case PRICE_TYPE:
      return {
        ...state,
        priceType: action.priceType,
      };
    case SWAP_RESULT:
      return {
        ...state,
        swapResult: action.swapResult,
      };
    case SLIP_PAGE:
      return {
        ...state,
        slipPage: action.slipPage,
      };
    case INFO_TOKEN_HISTORICAL_PRICE_ALL:
      return {
        ...state,
        infoTokenHistoricalPriceAll: action.infoTokenHistoricalPriceAll,
      };
    case PRICE_IMPACT:
      return {
        ...state,
        priceImpact: action.priceImpact,
      };
    case NETWORK_FEE:
      return {
        ...state,
        networkFee: action.networkFee,
      };
    case SUBSCRIBED_TOKEN:
      return {
        ...state,
        subscribedToken: action.subscribedToken,
      };

    case INFO_SUBSCRIBED_TOKEN:
      return {
        ...state,
        infoTokenSubscribed: action.infoTokenSubscribed,
      };
    case INFO_PRICE_SUBSCRIBED_TOKEN:
      return {
        ...state,
        infoTokenPriceSubscribed: action.infoTokenPriceSubscribed,
      };
    case INFO_BALANCE_SUBSCRIBED_TOKEN:
      return {
        ...state,
        infoTokenBalanceSubscribed: action.infoTokenBalanceSubscribed,
      };
    case INFO_WHITELISTED_TOKEN:
      return {
        ...state,
        infoTokenWhitelisted: action.infoTokenWhitelisted,
      };
    case INFO_PRICE_WHITELISTED_TOKEN:
      return {
        ...state,
        infoTokenPriceWhitelisted: action.infoTokenPriceWhitelisted,
      };
    case INFO_BALANCE_WHITELISTED_TOKEN:
      return {
        ...state,
        infoTokenBalanceWhitelisted: action.infoTokenBalanceWhitelisted,
      };
    case WHITELISTED_TOKEN:
      return {
        ...state,
        whitelistedToken: action.whitelistedToken,
      };
    case IPFS_ICON:
      return {
        ...state,
        ipfsIcon: action.ipfsIcon,
      };
    case SWAP_RESULT_ERROR_MESSAGE:
      return {
        ...state,
        swapResultErrorMessage: action.swapResultErrorMessage,
      };
    case MAX_BUY_EXCHANGE_AMOUNT:
      return {
        ...state,
        maxBuyExchangeAmount: action.maxBuyExchangeAmount,
      };
    case UNIT_TOKEN_PRICE:
      return {
        ...state,
        unitTokenPrice: action.unitTokenPrice,
      };
    default:
      return state;
  }
};

export const fieldIpfsIcon = (ipfsIcon) => {
  return { type: IPFS_ICON, ipfsIcon };
};

export const tokenTableAC = (tokenTable) => {
  return { type: TOKEN_DATA_TABLE, tokenTable };
};

export const fieldTokenSellAC = (fieldTokenSell, fieldTokenBuy) => {
  if (fieldTokenBuy != -1) {
    if (fieldTokenBuy < 0) fieldTokenBuy = '-';
    return { type: FIELD_WINDOW_ADDRESS, fieldTokenSell, fieldTokenBuy };
  }
  return { type: FIELD_WINDOW_ADDRESS, fieldTokenSell };
};

export const fieldTokenBuyAC = (fieldTokenBuy, fieldTokenSell) => {
  if (fieldTokenSell != -1) {
    if (fieldTokenSell < 0) fieldTokenSell = '-';
    return { type: FIELD_WINDOW_TOKEN, fieldTokenSell, fieldTokenBuy };
  }
  return { type: FIELD_WINDOW_TOKEN, fieldTokenBuy };
};

export const fieldTokenSellChooseAC = (fieldTokenSellChoose) => {
  return { type: FIELD_WINDOW_ADDRESS_CHOOSE, fieldTokenSellChoose };
};

export const fieldTokenBuyChooseAC = (fieldTokenBuyChoose) => {
  return { type: FIELD_WINDOW_TOKEN_CHOOSE, fieldTokenBuyChoose };
};

export const fieldsavePubKey = (savePubKey) => {
  return { type: FIELD_PUB_TOKEN, savePubKey };
};

export const fieldInfoToken = (infoToken) => {
  return { type: INFO_TOKEN, infoToken };
};

export const fieldInfoTokenPrice = (infoTokenPrice) => {
  return { type: INFO_TOKEN_PRICE, infoTokenPrice };
};

export const fieldInfoTokenBalance = (infoTokenBalance) => {
  return { type: INFO_TOKEN_BALANCE, infoTokenBalance };
};

export const fieldConfirmSwap = (confirmSwap) => {
  return { type: CONFIRM_SWAP, confirmSwap };
};

export const fieldTokenPrice = (tokenPrice) => {
  return { type: TOKEN_PRICE, tokenPrice };
};

export const fieldIsLogin = (isLogin) => {
  return { type: IS_LOGIN, isLogin };
};

export const fieldBalanceSweb = (balanceSwep) => {
  return { type: BALANCE_SWEP, balanceSwep };
};

export const fieldPriceSweb = (priceSweb) => {
  return { type: PRICE_SWEB, priceSweb };
};

export const fieldLogOut = () => {
  return { type: LOGOUT };
};

export const fieldInfoTokenPriceAll = (infoTokenPriceAll) => {
  return { type: INFO_TOKEN_PRICE_ALL, infoTokenPriceAll };
};

export const fieldInfoTokenHistoricalPriceAll = (
  infoTokenHistoricalPriceAll,
) => {
  return { type: INFO_TOKEN_HISTORICAL_PRICE_ALL, infoTokenHistoricalPriceAll };
};

export const fieldInfoTokenBalanceAll = (infoTokenBalanceAll) => {
  return { type: INFO_TOKEN_BALANCE_ALL, infoTokenBalanceAll };
};

export const fieldInfoTokenAll = (infoTokenAll) => {
  return { type: INFO_TOKEN_ALL, infoTokenAll };
};

export const fieldPriceType = (priceType) => {
  return { type: PRICE_TYPE, priceType };
};

export const fieldSwapResult = (swapResult) => {
  return { type: SWAP_RESULT, swapResult };
};

export const fieldSlipPage = (slipPage) => {
  return { type: SLIP_PAGE, slipPage };
};

export const fieldPriceImpact = (priceImpact) => {
  return { type: PRICE_IMPACT, priceImpact };
};

export const fieldNetworkFee = (networkFee) => {
  return { type: NETWORK_FEE, networkFee };
};

export const fieldSubscribedToken = (subscribedToken) => {
  return { type: SUBSCRIBED_TOKEN, subscribedToken };
};

export const fieldWhitelistedToken = (whitelistedToken) => {
  return { type: WHITELISTED_TOKEN, whitelistedToken };
};

export const fieldInfoTokenSubscribed = (infoTokenSubscribed) => {
  return { type: INFO_SUBSCRIBED_TOKEN, infoTokenSubscribed };
};

export const fieldInfoTokenWhitelisted = (infoTokenWhitelisted) => {
  return { type: INFO_WHITELISTED_TOKEN, infoTokenWhitelisted };
};

export const fieldInfoTokenBalanceSubscribed = (infoTokenBalanceSubscribed) => {
  return { type: INFO_BALANCE_SUBSCRIBED_TOKEN, infoTokenBalanceSubscribed };
};

export const fieldInfoTokenBalanceWhitelisted = (
  infoTokenBalanceWhitelisted,
) => {
  return { type: INFO_BALANCE_WHITELISTED_TOKEN, infoTokenBalanceWhitelisted };
};

export const fieldInfoTokenPriceSubscribed = (infoTokenPriceSubscribed) => {
  return { type: INFO_PRICE_SUBSCRIBED_TOKEN, infoTokenPriceSubscribed };
};

export const fieldInfoTokenPriceWhitelisted = (infoTokenPriceWhitelisted) => {
  return { type: INFO_PRICE_WHITELISTED_TOKEN, infoTokenPriceWhitelisted };
};
export const fieldSwapResultErrorMessage = (swapResultErrorMessage) => {
  return { type: SWAP_RESULT_ERROR_MESSAGE, swapResultErrorMessage };
};

export const fieldMaxBuyExchangeAmount = (maxBuyExchangeAmount) => {
  return { type: MAX_BUY_EXCHANGE_AMOUNT, maxBuyExchangeAmount };
};
export const fieldUnitTokenPrice = (unitTokenPrice) => {
  return { type: UNIT_TOKEN_PRICE, unitTokenPrice };
};

export default tokenReducer;
