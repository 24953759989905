export default function formatNumber(value: number): string {
  if (Math.abs(value) < 1e-10 && value !== 0) {
    const stringValue = value.toString();

    const parts = stringValue.split('e');

    if (parts.length === 2 && parts[1].startsWith('-')) {
      let decimalPart = parseFloat(parts[0]).toString();
      if (decimalPart.includes('.')) decimalPart = decimalPart.replace('.', '');
      const exponentPart = parseInt(parts[1].replace('-', ''), 10);
      return `0.${'0'.repeat(exponentPart - 1)}${decimalPart}`;
    }
  }

  const stringValue = value.toString();
  const [integerPart, decimalPart] = stringValue.split('.');
  let formattedDecimalPart = '';

  if (decimalPart) {
    const firstFiveDigits = decimalPart.slice(0, 5);
    let allZero = true;
    for (let k = 0; k < firstFiveDigits.length; k += 1) {
      if (firstFiveDigits[k] !== '0') {
        allZero = false;
        break;
      }
    }
    if (allZero) {
      let trailingZeros = '';
      for (let i = 5; i < decimalPart.length; i += 1) {
        if (decimalPart[i] === '0') {
          trailingZeros += '0';
        } else {
          formattedDecimalPart =
            firstFiveDigits + trailingZeros + decimalPart.slice(i);
          break;
        }
        if (i === decimalPart.length - 1) {
          formattedDecimalPart = firstFiveDigits + trailingZeros;
        }
      }
    } else formattedDecimalPart = firstFiveDigits;
  }
  return formattedDecimalPart
    ? `${integerPart}.${formattedDecimalPart}`
    : stringValue;
}
