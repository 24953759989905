import PropTypes from 'prop-types';
import iconArrow from '../../assets/img/arrowDown.svg';
import iconArrowDisabled from '../../assets/img/arrowDownDisabled.svg';

import styles from './ButtonArrow.module.css';

type ButtonArrowProps = {
  onClick: () => Promise<void>;
  inputDisabled: boolean;
};
const ButtonArrow = (props: ButtonArrowProps) => {
  return (
    // eslint-disable-next-line
    <button
      aria-label=""
      disabled={props.inputDisabled}
      className={styles.button}
      onClick={() => {
        if (!props.inputDisabled) props.onClick();
      }}
    >
      <img src={props.inputDisabled ? iconArrowDisabled : iconArrow} alt="" />
    </button>
  );
};

export default ButtonArrow;
