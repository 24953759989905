import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import QrScanner from 'qr-scanner';
import checkPubkey from 'utils/checkPubkey';
import styles from './styles.module.scss';

export const LoginQrScanner = (props) => {
  const videoElementRef = useRef(null);

  useEffect(() => {
    const video = videoElementRef.current;
    if (video) {
      const qrScanner = new QrScanner(
        video,
        (result) => {
          if (checkPubkey(result.data)) {
            props.onScan(result.data);
            qrScanner.stop();
            qrScanner.destroy();
          }
        },
        {
          returnDetailedScanResult: true,
          highlightScanRegion: true,
          highlightCodeOutline: true,
        },
      );
      qrScanner.start();
      return () => {
        qrScanner.stop();
        qrScanner.destroy();
      };
    }
    return () => {};
  }, [props]);

  return (
    <div className={styles.videoWrapper}>
      <video className={styles.qrVideo} ref={videoElementRef} />
    </div>
  );
};

LoginQrScanner.propTypes = {
  onScan: PropTypes.any,
};
