const RE = /\$\{\s*\w+\s*}/;

const isNotInterpolated = (kv: (string | null)[]): kv is [string, string] =>
  !!kv[0] && !!kv[1] && !RE.test(kv[1]);

const read = (): Record<string, string> =>
  Array.from(document.querySelectorAll('[itemid="config"] meta[itemprop]'))
    // map to k,v array
    .map((el) => [el.getAttribute('itemprop'), el.getAttribute('content')])
    // remove non-interpolated entries
    .filter(isNotInterpolated)
    // convert to object
    .reduce(
      (m, [k, v]) => ({
        ...m,
        [k]: v,
      }),
      {},
    );

const getSubscriptionEndpoint = (): string | undefined => {
  const { REACT_APP_API_ENDPOINT, REACT_APP_SUBSCRIPTION_API_ENDPOINT } =
    process.env;
  if (REACT_APP_SUBSCRIPTION_API_ENDPOINT) {
    return REACT_APP_SUBSCRIPTION_API_ENDPOINT;
  }
  if (REACT_APP_API_ENDPOINT) {
    return REACT_APP_API_ENDPOINT.replace(/^http/i, 'ws');
  }
  return undefined;
};

// The configuration variables are fetched from either the process environment,
// or, if available, metadata inside index.html.
//
// The reason for this is that when doing local development, using an .env file
// works well, but when running in production, the code is already built which
// means that process.env.* is not the environment variables available in the
// container, but rather the environment variables that were available when
// we built the container image.
//
// Thus, in production we instead fetch the configuration variables from the
// meta tags in index.html.
//
// The way this works is that before starting the web server, we run
// $ envsub build/index.html.template build/index.html
// See the `preserve` script in package.json.
//
// This substitutes the available runtime environment into index.html,
// and from there we can fetch them using the `read()` function.
//
// This makes it easy to override configuration variables without building a new image.
const {
  REACT_APP_SUBSCRIPTION_API_ENDPOINT = getSubscriptionEndpoint(),
  REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT as string,
  REACT_APP_IPFS_API_ENDPOINT = process.env
    .REACT_APP_IPFS_API_ENDPOINT as string,
  REACT_APP_REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL as string,
  REACT_APP_REDIRECT_ACTION_URL = process.env
    .REACT_APP_REDIRECT_ACTION_URL as string,
  REACT_APP_FEATURE_FLAG = process.env.REACT_APP_FEATURE_FLAG as string,
  REACT_APP_WHITELIST_API_ENDPOINT = process.env
    .REACT_APP_WHITELIST_API_ENDPOINT as string,
  REACT_APP_DEVNET = process.env.REACT_APP_DEVNET as string,
} = read();

export {
  REACT_APP_WHITELIST_API_ENDPOINT,
  REACT_APP_API_ENDPOINT,
  REACT_APP_IPFS_API_ENDPOINT,
  REACT_APP_SUBSCRIPTION_API_ENDPOINT,
  REACT_APP_REDIRECT_URL,
  REACT_APP_REDIRECT_ACTION_URL,
  REACT_APP_FEATURE_FLAG,
  REACT_APP_DEVNET,
};
