import HDKey from 'hdkey';
import { mnemonicToSeedSync } from 'bip39';
import secp256k1 from 'secp256k1';

export const mnemonicToHDKey = (mnemonic) => {
  const seed = mnemonicToSeedSync(mnemonic);
  const hdkey = HDKey.fromMasterSeed(seed);
  hdkey.sign = function sign(hash) {
    const sig = secp256k1.ecdsaSign(
      Uint8Array.from(hash),
      Uint8Array.from(hdkey.privateKey),
    );

    const array = secp256k1.signatureNormalize(sig.signature);
    return Buffer.from(secp256k1.signatureExport(array));
  };
  return hdkey;
};
