import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import checkPubkey from 'utils/checkPubkey';
import calculatePrice from 'utils/calculatePrice';
import { usePubKeyParam } from 'hooks/usePubKeyParam';
import { REACT_APP_DEVNET } from 'conf';
import {
  get_user_held_custom_tokens as getUserHeldCustomTokens,
  query_tokenization_block as queryTokenizationBlock,
  get_token_balance as getTokenBalance,
  TokenQuery,
} from '@coinweb/cweb-wallet-library';
import { useWallet } from 'hooks/useWallet';
import handleErrorMessage from 'utils/handleErrorMessage';
import round from 'utils/round';
import BottomCoinwebContainer from 'components/Containers/BottomCoinwebContainer';
import {
  fieldTokenSellAC,
  fieldTokenBuyAC,
  fieldTokenSellChooseAC,
  fieldTokenBuyChooseAC,
  fieldsavePubKey,
  fieldInfoToken,
  fieldInfoTokenPrice,
  fieldInfoTokenBalance,
  fieldConfirmSwap,
  // tokenTableAC,
  // fieldIsLogin,
  fieldBalanceSweb,
  fieldSwapResult,
  fieldPriceImpact,
  fieldPriceType,
  fieldNetworkFee,
  // fieldInfoTokenHistoricalPriceAll,
  fieldInfoTokenSubscribed,
  fieldInfoTokenWhitelisted,
  fieldInfoTokenBalanceSubscribed,
  fieldInfoTokenBalanceWhitelisted,
  fieldInfoTokenPriceSubscribed,
  fieldInfoTokenPriceWhitelisted,
  fieldSubscribedToken,
  fieldWhitelistedToken,
  fieldSwapResultErrorMessage,
  fieldMaxBuyExchangeAmount,
  fieldUnitTokenPrice,
} from '../../redux/reducers/tokenReducer';
import ButtonArrow from '../../components/ButtonArrow/ButtonArrow';
import MyInput from '../../components/MyInput/MyInput';
import MyButton from '../../components/MyButton/MyButton';
import ModalToken from '../../components/Modals/ModalToken/ModalToken';
import ModalRejected from '../../components/Modals/ModalRejected/ModalRejected';
import ModalCancelled from '../../components/Modals/ModalCancelled/ModalCancelled';
import ModalSubmitted from '../../components/Modals/ModalSubmitted/ModalSubmitted';
import ModalGetQr from '../../components/Modals/ModalGetQr/ModalGetQr';
import ModalSwapping from '../../components/Modals/ModalSwapping/ModalSwapping';
import ModalLogin from '../../components/Modals/ModalLogin/ModalLogin';
import SwapAccordion from '../../components/Accordion/Accordion';
import styles from './Exchange.module.css';
import iconSwapp from '../../assets/img/swap.png';
import iconCross from '../../assets/img/cross.svg';
import { getWhitelistToken } from '../../components/queries/whitelistToken';

const Exchange = () => {
  const pubKey = usePubKeyParam();
  const [wallet, setWalletPubKey] = useWallet();

  const dispatch = useDispatch();
  const [openToken, setOpenToken] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [openCancelled, setOpenCancelled] = useState(false);
  const [openGetQr, setOpenGetQr] = useState(false);
  const [openSubmitted, setOpenSubmitted] = useState(false);
  const [openSwapping, setOpenSwapping] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const handleOpenToken = () => setOpenToken(true);
  const handleCloseToken = () => setOpenToken(false);
  const handleOpenAddress = () => setOpenAddress(true);
  const handleCloseAddress = () => setOpenAddress(false);
  const handleCloseLogin = () => setOpenLogin(false);
  const handleCloseSubmitted = () => setOpenSubmitted(false);
  const handleOpenGetQr = () => setOpenGetQr(true);
  const handleCloseGetQr = () => setOpenGetQr(false);
  const handleCloseRejected = () => setOpenRejected(false);
  const handleCloseCancelled = () => setOpenCancelled(false);
  const [disableSwap, setDisableSwap] = useState(false);

  const {
    fieldTokenSell,
    fieldTokenBuy,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    savePubKey,
    infoToken,
    confirmSwap,
    isLogin,
    balanceSwep,
    infoTokenBalance,
    swapResult,
    priceType,
    subscribedToken,
    whitelistedToken,
    infoTokenSubscribed,
    infoTokenWhitelisted,
    infoTokenBalanceSubscribed,
    infoTokenBalanceWhitelisted,
    swapResultErrorMessage,
  } = useSelector((state) => state.tokenReducer);

  const handleReverse = async () => {
    const testFieldTokenSell = fieldTokenSell;
    const testFieldTokenBuy = fieldTokenBuy;
    const testFieldTokenBuyChoose = fieldTokenSellChoose;
    const testFieldTokenSellChoose = fieldTokenBuyChoose;

    if (!testFieldTokenBuyChoose || !testFieldTokenSellChoose) return;
    let j = 0;
    let flag = 0;

    if (
      testFieldTokenBuyChoose.hash === undefined &&
      testFieldTokenBuyChoose.name === 'CWEB'
    ) {
      testFieldTokenBuyChoose.id = 0;
      flag = 1;
    } else {
      const constHashSTBL =
        '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';
      if (testFieldTokenBuyChoose.hash === constHashSTBL) {
        const idSTBL = savePubKey.findIndex((e) => e === constHashSTBL);
        if (idSTBL !== -1) {
          testFieldTokenBuyChoose.id = idSTBL;
          flag = 1;
        }
      } else {
        infoToken.forEach((token) => {
          token.TokenQueryResult.LastExtraFields.Ok.forEach((element) => {
            if (element.name === 'symbol') {
              if (element.content === testFieldTokenBuyChoose.name) {
                testFieldTokenBuyChoose.id = j;
                flag = 1;
              }
            }
          });
          j += 1;
        });
      }
    }
    if (flag === 1) {
      let i = 0;
      if (
        testFieldTokenSellChoose.hash === undefined &&
        testFieldTokenSellChoose.name === 'CWEB'
      ) {
        testFieldTokenSellChoose.id = 0;
        dispatch(fieldTokenSellChooseAC(testFieldTokenSellChoose));
        dispatch(fieldTokenBuyChooseAC(testFieldTokenBuyChoose));
        flag = 2;
      } else {
        const constHashSTBL =
          '0xc9895090994839f5bbb111fbe86179325375f41e01ab7f3b9c20ebdb0830371b';
        if (testFieldTokenSellChoose.hash === constHashSTBL) {
          const idSTBL = savePubKey.findIndex((e) => e === constHashSTBL);
          if (idSTBL !== -1) {
            testFieldTokenSellChoose.id = idSTBL;
            dispatch(fieldTokenSellChooseAC(testFieldTokenSellChoose));
            dispatch(fieldTokenBuyChooseAC(testFieldTokenBuyChoose));
            flag = 2;
          }
        } else {
          infoToken.forEach((token) => {
            token.TokenQueryResult.LastExtraFields.Ok.forEach((element) => {
              if (element.name === 'symbol') {
                if (element.content === testFieldTokenSellChoose.name) {
                  testFieldTokenSellChoose.id = i;
                  dispatch(fieldTokenSellChooseAC(testFieldTokenSellChoose));
                  dispatch(fieldTokenBuyChooseAC(testFieldTokenBuyChoose));
                  flag = 2;
                }
              }
            });
            i += 1;
          });
        }
      }
    }
    if (flag === 2) {
      let price = {
        networkFee: '0',
        priceImpact: '0',
        tokenAmount: '0',
      };
      if (priceType !== 'sell') {
        dispatch(fieldPriceType('sell'));
        try {
          price = await calculatePrice(
            wallet,
            testFieldTokenBuy * 10 ** testFieldTokenSellChoose.exponent,
            testFieldTokenSellChoose.hash,
            testFieldTokenBuyChoose.hash,
            'sell',
            testFieldTokenBuyChoose.exponent,
          );
          if (price.tokenAmount > 0 && !price.err)
            dispatch(fieldSwapResult(true));
          else {
            dispatch(
              fieldSwapResultErrorMessage(handleErrorMessage(price.err)),
            );
            dispatch(fieldSwapResult(false));
          }
          dispatch(fieldPriceImpact(price.priceImpact));
          dispatch(fieldNetworkFee(price.networkFee));
          dispatch(fieldTokenSellAC(testFieldTokenBuy, price.tokenAmount));
        } catch {
          dispatch(
            fieldSwapResultErrorMessage(
              'Connection error. Please try again later',
            ),
          );
          dispatch(fieldSwapResult(false));
          dispatch(fieldTokenSellAC(testFieldTokenBuy, price.tokenAmount));
          dispatch(fieldPriceImpact(price.priceImpact));
          dispatch(fieldNetworkFee(price.networkFee));
        }
      } else {
        dispatch(fieldPriceType('buy'));
        try {
          price = await calculatePrice(
            wallet,
            testFieldTokenSell * 10 ** testFieldTokenBuyChoose.exponent,
            testFieldTokenSellChoose.hash,
            testFieldTokenBuyChoose.hash,
            'buy',
            testFieldTokenBuyChoose.exponent,
          );
          if (price.tokenAmount > 0 && !price.err)
            dispatch(fieldSwapResult(true));
          else {
            dispatch(
              fieldSwapResultErrorMessage(handleErrorMessage(price.err)),
            );
            dispatch(fieldSwapResult(false));
          }
          dispatch(fieldPriceImpact(price.priceImpact));
          dispatch(fieldNetworkFee(price.networkFee));
          dispatch(fieldTokenBuyAC(testFieldTokenSell, price.tokenAmount));
        } catch {
          dispatch(
            fieldSwapResultErrorMessage(
              'Connection error. Please try again later',
            ),
          );
          dispatch(fieldSwapResult(false));
          dispatch(fieldTokenBuyAC(testFieldTokenSell, price.tokenAmount));
          dispatch(fieldPriceImpact(price.priceImpact));
          dispatch(fieldNetworkFee(price.networkFee));
        }
      }
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [pubKeys, setPubKey] = useState('');

  const handleOpenSwapping = async () => {
    setOpenSwapping(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const isDevEnvironment = REACT_APP_DEVNET === 'true';

    document.documentElement.style.setProperty(
      '--background-gradient',
      isDevEnvironment
        ? 'linear-gradient(to top, #006874, #003737)'
        : 'linear-gradient(to top, #1f1d47, #0a0b43)',
    );
    document.documentElement.style.setProperty(
      '--background-color',
      isDevEnvironment ? '#0f3e43' : '#0e0133',
    );
    document.documentElement.style.setProperty(
      '--background-color-2',
      isDevEnvironment ? '#1e4b51' : '#332357',
    );
    document.documentElement.style.setProperty(
      '--background-color-arrow-button',
      isDevEnvironment ? '#1b464a' : '#13254a',
    );
    document.documentElement.style.setProperty(
      '--background-color-modal',
      isDevEnvironment ? '#002e34' : '#0f0f3a',
    );
    document.documentElement.style.setProperty(
      '--main-page-color',
      isDevEnvironment ? '#0d2522' : '#0D0F1E',
    );
    document.documentElement.style.setProperty(
      '--main-background-color',
      isDevEnvironment ? '#122516' : '#121625',
    );
    document.documentElement.style.setProperty(
      '--background-color-modal-2',
      isDevEnvironment ? '#003532' : '#100035',
    );
    document.documentElement.style.setProperty(
      '--main-box-shadow-color',
      isDevEnvironment ? 'rgba(18, 103, 75, 0.5)' : 'rgba(0, 0, 0, 0.31)',
    );
    document.documentElement.style.setProperty(
      '--item-background-hover-color',
      isDevEnvironment ? '#2e5555' : '#1D202A',
    );
    document.documentElement.style.setProperty(
      '--main-podBlue-color',
      isDevEnvironment ? '#0f564d' : '#1D2C50',
    );
    document.documentElement.style.setProperty(
      '--main-grayInput-color',
      isDevEnvironment ? '#a9ccc4' : '#5E6887',
    );
  });

  useEffect(() => {
    if (wallet === undefined && pubKey !== null) {
      if (checkPubkey(pubKey)) {
        setPubKey(pubKey);
        setWalletPubKey(pubKey);
        // setOpen(true);
      } else {
        navigate('/');
      }
    }
  }, [
    pubKeys,
    searchParams,
    setSearchParams,
    navigate,
    pubKey,
    setWalletPubKey,
    wallet,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const balance = round(
        Number(fieldTokenSellChoose.balance) /
          10 ** fieldTokenSellChoose.exponent,
      );

      const price = await calculatePrice(
        wallet,
        Number(balance) * 10 ** fieldTokenSellChoose.exponent,
        fieldTokenSellChoose.hash,
        fieldTokenBuyChoose.hash,
        'sell',
        fieldTokenBuyChoose.exponent,
      );
      dispatch(fieldMaxBuyExchangeAmount(price.tokenAmount));

      const unitPrice = await calculatePrice(
        wallet,
        1 * 10 ** fieldTokenSellChoose.exponent,
        fieldTokenSellChoose.hash,
        fieldTokenBuyChoose.hash,
        'sell',
        fieldTokenBuyChoose.exponent,
      );
      dispatch(fieldUnitTokenPrice(unitPrice.tokenAmount));
    };
    if (
      Object.keys(fieldTokenBuyChoose).length !== 0 &&
      Object.keys(fieldTokenSellChoose).length !== 0
    )
      fetchData();
  }, [fieldTokenBuyChoose, fieldTokenSellChoose, dispatch, wallet]);

  const handleChange = (key) => {
    setOpenLogin(false);
    setPubKey(key);
    setWalletPubKey(key);
    // setOpen(true);
    setPubKey(key);
  };

  async function getInfo() {
    let buyTokenParam = '';
    let sellTokenParam = '';
    if ('id' in fieldTokenSellChoose) {
      if (fieldTokenSellChoose.hash === undefined) {
        buyTokenParam = 'CWEB';
      } else {
        buyTokenParam = fieldTokenSellChoose.hash;
      }
    }
    if ('id' in fieldTokenBuyChoose) {
      if (fieldTokenBuyChoose.hash === undefined) {
        sellTokenParam = 'CWEB';
      } else {
        sellTokenParam = fieldTokenBuyChoose.hash;
      }
    }
    const tokens = await getUserHeldCustomTokens(wallet);
    const swebBalance = await getTokenBalance(wallet, null);
    const onChainQuery = [];
    const tokenPriceQuery = [];
    const tokenSupplyQuery = [];
    const tokenBondingCurve = [];
    tokens.forEach((token) => {
      onChainQuery.push({
        TokenQuery: [token, TokenQuery.LastExtraFields],
      });
      tokenPriceQuery.push({
        TokenQuery: [token, TokenQuery.CurrentPrice],
      });
      tokenSupplyQuery.push({
        TokenQuery: [token, TokenQuery.TotalSupply],
      });
      tokenBondingCurve.push({
        TokenQuery: [token, TokenQuery.BondingCurve],
      });
    });

    const validateTokens = await queryTokenizationBlock(
      wallet,
      tokenBondingCurve,
    );

    let j = 0;
    validateTokens.forEach((token) => {
      if (
        token.TokenQueryResult.BondingCurve.Ok === null ||
        token.TokenQueryResult.BondingCurve.Ok === undefined
      ) {
        onChainQuery.splice(j, 1);
        tokenPriceQuery.splice(j, 1);
        tokenSupplyQuery.splice(j, 1);
        tokens.splice(j, 1);
        j -= 1;
      }
      j += 1;
    });
    const tokensInfo = await queryTokenizationBlock(wallet, onChainQuery);
    const tokensPrice = await queryTokenizationBlock(wallet, tokenPriceQuery);

    const tokensSupply = [];
    // eslint-disable-next-line
    for (const token of tokens) {
      const tokenBalanceW = await getTokenBalance(wallet, token); // eslint-disable-line
      tokensSupply.push(tokenBalanceW);
    }

    const tokenSet = new Set(tokens);

    const subscribedTokenNew = subscribedToken.filter((e) => !tokenSet.has(e));

    const subscribedTokenSet = new Set(subscribedTokenNew);
    const whitelistedTokenNowError = await getWhitelistToken();
    const whitelistedTokenNow = [];
    const correctString = '0x';

    whitelistedTokenNowError.forEach((token) => {
      whitelistedTokenNow.push(correctString + token);
    });

    let whitelistedTokenNew = whitelistedTokenNow.filter(
      (e) => !tokenSet.has(e),
    );

    whitelistedTokenNew = whitelistedTokenNew.filter(
      (e) => !subscribedTokenSet.has(e),
    );

    const subscribedTokenFields = [];
    const subscribedTokenPrice = [];
    const subscribedTokenSupply = [];
    const subscribedTokenBondingCurve = [];
    subscribedTokenNew.forEach((token) => {
      subscribedTokenFields.push({
        TokenQuery: [token, TokenQuery.LastExtraFields],
      });
      subscribedTokenPrice.push({
        TokenQuery: [token, TokenQuery.CurrentPrice],
      });
      subscribedTokenBondingCurve.push({
        TokenQuery: [token, TokenQuery.BondingCurve],
      });
    });
    const subscribedValidateTokens = await queryTokenizationBlock(
      wallet,
      subscribedTokenBondingCurve,
    );

    j = 0;
    subscribedValidateTokens.forEach((token) => {
      if (
        token.TokenQueryResult.BondingCurve.Ok === null ||
        token.TokenQueryResult.BondingCurve.Ok === undefined
      ) {
        subscribedTokenFields.splice(j, 1);
        subscribedTokenPrice.splice(j, 1);
        subscribedTokenNew.splice(j, 1);
        j -= 1;
      }
      j += 1;
    });
    // eslint-disable-next-line
    for (const token of subscribedTokenNew) {
      const tokenBalanceWA = await getTokenBalance(wallet, token.token); // eslint-disable-line
      subscribedTokenSupply.push(tokenBalanceWA);
    }
    const subscribedTokenFieldsQ = await queryTokenizationBlock(
      wallet,
      subscribedTokenFields,
    );

    const subscribedTokenPriceQ = await queryTokenizationBlock(
      wallet,
      subscribedTokenPrice,
    );

    const whitelistedTokenFields = [];
    const whitelistedTokenPrice = [];
    const whitelistedTokenSupply = [];
    const whitelistedTtokenBondingCurve = [];
    whitelistedTokenNew.forEach((token) => {
      whitelistedTokenFields.push({
        TokenQuery: [token, TokenQuery.LastExtraFields],
      });
      whitelistedTokenPrice.push({
        TokenQuery: [token, TokenQuery.CurrentPrice],
      });
      whitelistedTtokenBondingCurve.push({
        TokenQuery: [token, TokenQuery.BondingCurve],
      });
    });

    const whitelistedValidateTokens = await queryTokenizationBlock(
      wallet,
      whitelistedTtokenBondingCurve,
    );

    j = 0;
    whitelistedValidateTokens.forEach((token) => {
      if (
        token.TokenQueryResult.BondingCurve.Ok === null ||
        token.TokenQueryResult.BondingCurve.Ok === undefined
      ) {
        whitelistedTokenFields.splice(j, 1);
        whitelistedTokenPrice.splice(j, 1);
        whitelistedTokenNew.splice(j, 1);
        j -= 1;
      }
      j += 1;
    });
    // eslint-disable-next-line
    for (const token of whitelistedTokenNew) {
      const tokenBalanceWA = await getTokenBalance(wallet, token); // eslint-disable-line
      whitelistedTokenSupply.push(tokenBalanceWA);
    }
    const whitelistedTokenFieldsQ = await queryTokenizationBlock(
      wallet,
      whitelistedTokenFields,
    );

    const whitelistedTokenPriceQ = await queryTokenizationBlock(
      wallet,
      whitelistedTokenPrice,
    );

    // setOptions(tokens);
    dispatch(fieldInfoTokenBalance(tokensSupply));
    dispatch(fieldsavePubKey(tokens));
    dispatch(fieldInfoToken(tokensInfo));
    dispatch(fieldInfoTokenPrice(tokensPrice));
    dispatch(fieldBalanceSweb(swebBalance));
    dispatch(fieldInfoTokenSubscribed(subscribedTokenFieldsQ));
    dispatch(fieldInfoTokenWhitelisted(whitelistedTokenFieldsQ));
    dispatch(fieldInfoTokenBalanceSubscribed(subscribedTokenSupply));
    dispatch(fieldInfoTokenBalanceWhitelisted(whitelistedTokenSupply));
    dispatch(fieldInfoTokenPriceSubscribed(subscribedTokenPriceQ));
    dispatch(fieldInfoTokenPriceWhitelisted(whitelistedTokenPriceQ));
    dispatch(fieldSubscribedToken(subscribedTokenNew));
    dispatch(fieldWhitelistedToken(whitelistedTokenNew));
    if (sellTokenParam !== '') {
      let idB = fieldTokenBuyChoose.id;
      let balanceNew = fieldTokenBuyChoose.balance;
      if (sellTokenParam === 'CWEB') {
        idB = 0;
        balanceNew = swebBalance;
      } else {
        const idB1 = tokens.indexOf(sellTokenParam);
        const idB2 = subscribedTokenNew.indexOf(sellTokenParam);
        const idB3 = whitelistedTokenNew.indexOf(sellTokenParam);
        if (idB1 !== -1) {
          idB = idB1 + 1;
          balanceNew = tokensSupply[idB1];
        }
        if (idB2 !== -1) {
          idB = idB2 + 1 + subscribedTokenNew.length;
          balanceNew = subscribedTokenSupply[idB2];
        }
        if (idB3 !== -1) {
          idB =
            idB3 + 1 + subscribedTokenNew.length + whitelistedTokenNew.length;
          balanceNew = whitelistedTokenSupply[idB3];
        }
      }
      dispatch(
        fieldTokenBuyChooseAC({
          id: idB,
          name: fieldTokenBuyChoose.name,
          img: fieldTokenBuyChoose.img,
          imgH: fieldTokenBuyChoose.imgH,
          exponent: fieldTokenBuyChoose.exponent,
          hash: fieldTokenBuyChoose.hash,
          balance: balanceNew,
        }),
      );
    }
    if (buyTokenParam !== '') {
      let idS = fieldTokenSellChoose.id;
      let balanceNew = fieldTokenSellChoose.balance;
      if (buyTokenParam === 'CWEB') {
        idS = 0;
        balanceNew = swebBalance;
      } else {
        const idS1 = tokens.indexOf(buyTokenParam);
        const idS2 = subscribedTokenNew.indexOf(buyTokenParam);
        const idS3 = whitelistedTokenNew.indexOf(buyTokenParam);
        if (idS1 !== -1) {
          idS = idS1 + 1;
          balanceNew = tokensSupply[idS1];
        }
        if (idS2 !== -1) {
          idS = idS2 + 1 + subscribedTokenNew.length;
          balanceNew = subscribedTokenSupply[idS2];
        }
        if (idS3 !== -1) {
          idS =
            idS3 + 1 + subscribedTokenNew.length + whitelistedTokenNew.length;
          balanceNew = whitelistedTokenSupply[idS3];
        }
      }
      dispatch(
        fieldTokenSellChooseAC({
          id: idS,
          name: fieldTokenSellChoose.name,
          img: fieldTokenSellChoose.img,
          imgH: fieldTokenSellChoose.imgH,
          exponent: fieldTokenSellChoose.exponent,
          hash: fieldTokenSellChoose.hash,
          balance: balanceNew,
        }),
      );
    }
  }

  const handleSwappingRequest = async () => {
    await getInfo();
    let price = {
      networkFee: '0',
      priceImpact: '0',
      tokenAmount: '0',
      err: undefined,
    };
    if (
      fieldTokenBuyChoose.id === undefined ||
      fieldTokenSellChoose.id === undefined
    )
      return;
    try {
      if (priceType === 'sell') {
        price = await calculatePrice(
          wallet,
          fieldTokenSell * 10 ** fieldTokenSellChoose.exponent,
          fieldTokenSellChoose.hash,
          fieldTokenBuyChoose.hash,
          priceType,
          fieldTokenBuyChoose.exponent,
        );
        if (price.tokenAmount > 0 && !price.err)
          dispatch(fieldSwapResult(true));
        else {
          dispatch(fieldSwapResultErrorMessage(handleErrorMessage(price.err)));
          dispatch(fieldSwapResult(false));
        }
        dispatch(fieldTokenSellAC(fieldTokenSell, price.tokenAmount));
        dispatch(fieldPriceImpact(price.priceImpact));
        dispatch(fieldNetworkFee(price.networkFee));
      } else {
        price = await calculatePrice(
          wallet,
          10 ** fieldTokenSellChoose.exponent,
          fieldTokenSellChoose.hash,
          fieldTokenBuyChoose.hash,
          priceType,
          fieldTokenBuyChoose.exponent,
        );
        if (price.tokenAmount > 0 && !price.err)
          dispatch(fieldSwapResult(true));
        else {
          dispatch(fieldSwapResultErrorMessage(handleErrorMessage(price.err)));
          dispatch(fieldSwapResult(false));
        }
        dispatch(fieldTokenBuyAC(fieldTokenBuy, price.tokenAmount));
        dispatch(fieldPriceImpact(price.priceImpact));
        dispatch(fieldNetworkFee(price.networkFee));
      }
    } catch {
      dispatch(
        fieldSwapResultErrorMessage('Connection error. Please try again later'),
      );
      dispatch(fieldTokenSellAC(fieldTokenSell, price.tokenAmount));
      dispatch(fieldPriceImpact(price.priceImpact));
      dispatch(fieldNetworkFee(price.networkFee));
    }
  };

  const handleCloseSwapping = () => {
    getInfo();
    setOpenSwapping(false);
  };

  const handleChangeConfirmSwap = () => {
    const balanceSell =
      Number(fieldTokenSellChoose.balance) /
      10 ** fieldTokenSellChoose.exponent;

    if (
      swapResult &&
      fieldTokenSell > 0 &&
      fieldTokenBuy > 0 &&
      fieldTokenSell <= balanceSell
    ) {
      handleOpenSwapping();
    } else dispatch(fieldSwapResult(false));
  };

  const handleChangeCancelConfirmSwap = () => {
    dispatch(fieldConfirmSwap(false));
  };

  return (
    <div className={styles.container}>
      <div className={styles.window}>
        <div className={styles.header}>
          <p>Swap</p>
          {confirmSwap ? (
            <div onClick={handleChangeCancelConfirmSwap}>
              <img src={iconCross} alt="" />
            </div>
          ) : (
            <div onClick={handleSwappingRequest}>
              <img src={iconSwapp} alt="" />
            </div>
          )}
        </div>
        <div>
          <div className={styles.blockInputs}>
            <MyInput
              inputDisabled={confirmSwap}
              calType="sell"
              wallet={wallet}
              textBtn={fieldTokenSellChoose}
              onClickBtn={handleOpenAddress}
              value={fieldTokenSell}
              onChange={fieldTokenSellAC}
              onChangeOther={fieldTokenBuyAC}
            />
            <div className={styles.btnArrow}>
              <ButtonArrow
                inputDisabled={
                  confirmSwap ||
                  ((fieldTokenSell === 0 ||
                    fieldTokenSell === '0' ||
                    fieldTokenSell === undefined ||
                    fieldTokenSell === '' ||
                    Number.isNaN(fieldTokenSell)) &&
                    (fieldTokenBuy === 0 ||
                      fieldTokenBuy === '0' ||
                      fieldTokenBuy === undefined ||
                      fieldTokenBuy === '' ||
                      Number.isNaN(fieldTokenBuy)))
                }
                onClick={handleReverse}
              />
            </div>
            <MyInput
              inputDisabled={confirmSwap}
              calType="buy"
              wallet={wallet}
              onChangeOther={fieldTokenSellAC}
              value={fieldTokenBuy}
              onChange={fieldTokenBuyAC}
              textBtn={fieldTokenBuyChoose}
              onClickBtn={handleOpenToken}
            />
          </div>
          {isLogin && (
            <>
              {fieldTokenSell > 0 && fieldTokenBuy > 0 && swapResult && (
                <div>
                  <SwapAccordion onChangeSwapDisable={setDisableSwap} />
                </div>
              )}
              {!swapResult && (fieldTokenSell > 0 || fieldTokenBuy > 0) && (
                <div className={styles.validityName}>
                  <div className={styles.validityBlockName}>
                    {fieldTokenBuyChoose.id === undefined ||
                    fieldTokenSellChoose === undefined ? (
                      <p>Select pair of tokens</p>
                    ) : (
                      <p> {swapResultErrorMessage}</p>
                    )}
                  </div>
                </div>
              )}
              {confirmSwap ? (
                <>
                  <div className={styles.footer}>
                    <div className={styles.footerBlockName}>
                      <p>
                        Output is estimated. You will receile at least{' '}
                        <spam>{fieldTokenBuy * (1 - 0.23)} KEYS</spam> or the
                        transaction will revert.
                      </p>
                    </div>
                  </div>
                  <div onClick={handleOpenGetQr}>
                    <MyButton
                      title="Confirm SWAP"
                      className={styles.btnWallet}
                    />
                  </div>
                </>
              ) : (
                <div onClick={handleChangeConfirmSwap}>
                  <MyButton
                    disabled={!swapResult || disableSwap}
                    title="SWAP"
                    className={styles.btnWallet}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalToken
        calType="sell"
        wallet={wallet}
        open={openToken}
        handleClose={handleCloseToken}
        onChange={fieldTokenBuyChooseAC}
        onValueAddressChange={fieldTokenSellAC}
        onValueTokenChange={fieldTokenBuyAC}
        selected={fieldTokenBuyChoose}
        headersList1={infoToken}
        headersList2={infoTokenSubscribed}
        headersList3={infoTokenWhitelisted}
        tokens1={savePubKey}
        tokens2={subscribedToken}
        tokens3={whitelistedToken}
        balance1={infoTokenBalance}
        balance2={infoTokenBalanceSubscribed}
        balance3={infoTokenBalanceWhitelisted}
        balanceCweb={balanceSwep}
      />
      <ModalToken
        calType="buy"
        wallet={wallet}
        open={openAddress}
        handleClose={handleCloseAddress}
        onChange={fieldTokenSellChooseAC}
        onValueAddressChange={fieldTokenSellAC}
        onValueTokenChange={fieldTokenBuyAC}
        selected={fieldTokenSellChoose}
        headersList1={infoToken}
        headersList2={[]}
        headersList3={[]}
        tokens1={savePubKey}
        tokens2={[]}
        tokens3={[]}
        balance1={infoTokenBalance}
        balance2={infoTokenBalanceSubscribed}
        balance3={infoTokenBalanceWhitelisted}
        balanceCweb={balanceSwep}
      />
      <ModalRejected open={openRejected} handleClose={handleCloseRejected} />
      <ModalCancelled open={openCancelled} handleClose={handleCloseCancelled} />
      <ModalSubmitted open={openSubmitted} handleClose={handleCloseSubmitted} />
      <ModalSwapping open={openSwapping} handleClose={handleCloseSwapping} />
      <ModalGetQr open={openGetQr} handleClose={handleCloseGetQr} />
      <ModalLogin
        open={openLogin}
        handleClose={handleCloseLogin}
        onChange={handleChange}
        activeLogin={false}
      />
      <BottomCoinwebContainer />
    </div>
  );
};
export default Exchange;
