import { request, gql } from 'graphql-request';
import { REACT_APP_IPFS_API_ENDPOINT } from '../conf';

const getIpfsPinQuery = gql`
  query getPin($hash: String!) {
    getPin(hash: $hash)
  }
`;

export default async function fullUrl(hash) {
  try {
    const res = await request(REACT_APP_IPFS_API_ENDPOINT, getIpfsPinQuery, {
      hash,
    });

    const base64imgarr = res.getPin;
    const TYPED_ARRAY = new Uint8Array(base64imgarr);

    const STRING_CHAR = TYPED_ARRAY.reduce((sdata, byte) => {
      return sdata + String.fromCharCode(byte);
    }, '');

    const base64String = btoa(STRING_CHAR);
    return `data:image/png;base64,${base64String}`;
  } catch {
    return ``;
  }
}
