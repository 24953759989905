import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import iconReject from 'assets/img/reject.png';
import styles from './ModalCancelled.module.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

export type Props = {
  open: boolean;
  handleClose: () => void;
  onChange?: (key: any) => void;
  token?: string;
  activeLogin?: any;
};

const ModalCancelled = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.admissible}>
            <img src={iconReject} alt="" />
          </div>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p>Connection canceled</p>
            </div>
          </div>
          <div className={styles.redBlock} onClick={props.handleClose}>
            <div className={styles.redText}>
              <p>Dismiss</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default ModalCancelled;
