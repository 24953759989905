import {
  create_wallet as createWallet,
  subscribe_to_notifications as subscribeToNotifications,
  Wallet,
  wasmLoadingPromise,
  from_hex_string as fromHexString,
  sign,
} from '@coinweb/cweb-wallet-library';
import { mnemonicToHDKey } from './cwbBlockchain';
import { REACT_APP_API_ENDPOINT } from '../conf';

export const walletWithKey = async (mnemonic: string): Promise<Wallet> => {
  const address = `${REACT_APP_API_ENDPOINT}/wallet`;
  const wsAddress = address.replace(/^https:/, 'wss:');

  if (wasmLoadingPromise !== undefined) {
    await wasmLoadingPromise;
  }

  const hdkey = mnemonicToHDKey(mnemonic);
  // 'priority supply couple broccoli balcony sort flag keep original wrong pottery version'
  // 'similar library stage coil transfer evil family note evidence fine toe quiz',

  const wallet = await createWallet({
    address,
    ws_address: wsAddress,
    max_retry_time_secs: 30,
    pub_key: hdkey.publicKey.toString('hex'),
    shard: null,
    sign_callback: (msg) => {
      return sign(fromHexString(hdkey.privateKey.toString('hex')), msg);
    },
    enable_retries: true,
  });

  const subscription = subscribeToNotifications(wallet, undefined, {
    keepAlive: 10_000,
    retryAttempts: 100,
    shouldRetry: (error) => {
      // eslint-disable-next-line no-console
      console.error('WS error:', error);
      return true;
    },
    onNonLazyError: (errorOrCloseEvent) => {
      // eslint-disable-next-line no-console
      console.error('WS error or close event:', errorOrCloseEvent);
    },
    on: {
      error: (received) => {
        // eslint-disable-next-line no-console
        console.error('WS `error`:', received);
      },
      closed: (received) => {
        // eslint-disable-next-line no-console
        console.warn('WS `closed`:', received);
      },
    },
  });
  // NOTE: call to `subscribe` is needed, even if supplied lambda
  // will do nothing
  subscription.subscribe((x) => {});
  return wallet;
};
