/* global BigInt */
import {
  query_tokenization_block as queryTokenizationBlock,
  ExchangeForCwebQuery,
  ExchangeViaCwebQuery,
  ExchangeForTokenQuery,
} from '@coinweb/cweb-wallet-library';
import round from 'utils/round';
import formatNumber from 'utils/formatNumber';

export default async function calculatePrice(
  wallet,
  price,
  buyToken,
  sellToken,
  caclutateType,
  exponent,
) {
  price = Number(price.toFixed());
  try {
    let calculate;
    let priceImpact = 0;
    let networkFee = 0;
    let returnPrice = 0;
    if (wallet === undefined) {
      return {
        networkFee: '0',
        priceImpact: '0',
        tokenAmount: '0',
        err: 'Can not connect to wallet.',
      };
    }
    if (buyToken === undefined && sellToken === undefined) {
      return {
        networkFee: '0',
        priceImpact: '0',
        tokenAmount: '0',
        err: 'There is no selected tokens.',
      };
    }
    if (caclutateType === 'sell') {
      if (buyToken === undefined) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeViaCweb: [
              [sellToken, BigInt(price)],
              ExchangeViaCwebQuery.CalcBuyTokenViaCweb,
            ],
          },
        ]);

        if (calculate[0].ExchangeViaCwebResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeViaCwebResult.Err,
          };
        }

        priceImpact = calculate[0].ExchangeViaCwebResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeViaCwebResult.Ok.network_fee);
        calculate = calculate[0].ExchangeViaCwebResult.Ok.token_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
      if (sellToken === undefined) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeForCweb: [
              [buyToken, BigInt(price)],
              ExchangeForCwebQuery.CalcSellToken,
            ],
          },
        ]);
        if (calculate[0].ExchangeForCwebResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeForCwebResult.Err,
          };
        }
        priceImpact = calculate[0].ExchangeForCwebResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeForCwebResult.Ok.network_fee);
        calculate = calculate[0].ExchangeForCwebResult.Ok.cweb_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
      if (sellToken && buyToken) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeForToken: [
              {
                token_a: buyToken,
                token_b: sellToken,
                amount: BigInt(price),
              },
              ExchangeForTokenQuery.CalcSellForToken,
            ],
          },
        ]);

        if (calculate[0].ExchangeForTokenResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeForTokenResult.Err,
          };
        }
        priceImpact = calculate[0].ExchangeForTokenResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeForTokenResult.Ok.network_fee);
        calculate = calculate[0].ExchangeForTokenResult.Ok.token_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
    } else {
      if (sellToken === undefined) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeViaCweb: [
              [buyToken, BigInt(price)],
              ExchangeViaCwebQuery.CalcSellTokenViaCweb,
            ],
          },
        ]);

        if (calculate[0].ExchangeViaCwebResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeViaCwebResult.Err,
          };
        }
        priceImpact = calculate[0].ExchangeViaCwebResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeViaCwebResult.Ok.network_fee);
        calculate = calculate[0].ExchangeViaCwebResult.Ok.token_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
      if (buyToken === undefined) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeForCweb: [
              [sellToken, BigInt(price)],
              ExchangeForCwebQuery.CalcBuyToken,
            ],
          },
        ]);
        if (calculate[0].ExchangeForCwebResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeForCwebResult.Err,
          };
        }
        priceImpact = calculate[0].ExchangeForCwebResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeForCwebResult.Ok.network_fee);
        calculate = calculate[0].ExchangeForCwebResult.Ok.cweb_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
      if (sellToken && buyToken) {
        calculate = await queryTokenizationBlock(wallet, [
          {
            ExchangeForToken: [
              {
                token_a: buyToken,
                token_b: sellToken,
                amount: BigInt(price),
              },
              ExchangeForTokenQuery.CalcBuyForToken,
            ],
          },
        ]);
        if (calculate[0].ExchangeForTokenResult.Err) {
          return {
            networkFee: '0',
            priceImpact: '0',
            tokenAmount: '0',
            err: calculate[0].ExchangeForTokenResult.Err,
          };
        }
        priceImpact = calculate[0].ExchangeForTokenResult.Ok.price_impact;
        networkFee = Number(calculate[0].ExchangeForTokenResult.Ok.network_fee);
        calculate = calculate[0].ExchangeForTokenResult.Ok.token_amount;
        returnPrice = Number(calculate) / 10 ** exponent;
        return {
          networkFee: formatNumber(networkFee),
          priceImpact: formatNumber(priceImpact),
          tokenAmount: formatNumber(returnPrice),
          err: undefined,
        };
      }
    }

    return {
      networkFee: '0',
      priceImpact: '0',
      tokenAmount: '0',
      err: 'Connection error. Please try again later.',
    };
  } catch (err) {
    return {
      networkFee: '0',
      priceImpact: '0',
      tokenAmount: '0',
      err: 'Connection error. Please try again later.',
    };
  }
}
