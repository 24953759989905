import QRCode from 'react-qr-code';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import calculateQr from 'utils/calculateQr';
import { useSelector } from 'react-redux';
import iconTransaction from 'assets/img/transaction.png';
import styles from './ModalGetQr.module.css';
import { Props } from '../ModalCancelled/ModalCancelled';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '12px',
  width: 320,
  bgcolor: 'background.paper',
  border: '1px solid rgba(153, 161, 189, 0.14)',
  boxShadow: 24,
  color: 'var(--main-white-color)',
  background: 'var(--main-page-color)',
  p: '16px',
};

const ModalGetQr = (props: Props) => {
  const {
    fieldTokenSell,
    fieldTokenBuy,
    fieldTokenSellChoose,
    fieldTokenBuyChoose,
    priceType,
    slipPage,
  } = useSelector((state: any) => state.tokenReducer);

  let qrJson = '';
  if (props.open) {
    qrJson =
      calculateQr(
        fieldTokenSell * 10 ** fieldTokenSellChoose.exponent,
        fieldTokenBuy * 10 ** fieldTokenBuyChoose.exponent,
        fieldTokenSellChoose.hash,
        fieldTokenBuyChoose.hash,
        priceType,
        slipPage,
      ) ?? '';
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.admissible}>
            <QRCode
              size={540}
              style={{
                height: 'auto',
                maxWidth: '100%',
                width: '100%',
              }}
              bgColor="transparent"
              fgColor="#fff"
              value={qrJson}
              viewBox="0 0 540 540"
            />
          </div>
          <div className={styles.validityComment}>
            <div className={styles.validityCommentBlock}>
              <img src={iconTransaction} alt="" />
              <p>View on Coinweb Explorer</p>
            </div>
          </div>
          <div className={styles.greenBlock} onClick={props.handleClose}>
            <div className={styles.greenText}>
              <p>Close</p>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalGetQr;
