import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const ButterBar = () => (
  <Box
    sx={{
      width: '100%',
      backgroundColor: '#ff7442',
      minHeight: '20px',
      display: 'flex',
      justifyContent: 'center',
      zIndex: 9999,
      position: 'fixed',
    }}
  >
    <Typography fontSize="12px" sx={{ marginTop: '0px', marginBottom: '0px' }}>
      Connecting...
    </Typography>
  </Box>
);
