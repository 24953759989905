import { useMemo } from 'react';
import { AxisOptions, AxisLinearOptions, Chart, UserSerie } from 'react-charts';
import Box from '@mui/material/Box';
import round from 'utils/round';
import styles from './LineChart.module.css';

type Props = {
  data:
    | Array<{ primary: number; secondary: number }>
    | Array<{
        data: Array<{ primary: number; secondary: number }>;
        label: string;
      }>;
  height?: number | string;
  width?: number | string;
  widthXs?: number | string;
  widthSm?: number | string;
  widthMd?: number | string;
  widthLg?: number | string;
  widthXl?: number | string;
  yTokenName: string;
  oldPrice: number;
};

type priceProps = {
  val: number;
};
type TDatum = { primary: number; secondary: number };
let OLDPRICE = 1;

const PriceValue = (props: priceProps): JSX.Element => {
  const { val } = props;
  let change = 0;
  let value = 0;
  if (val) {
    change = Number(round(((val - OLDPRICE) / OLDPRICE) * 100));
    value = Number(round(val));
  }

  return (
    <div className={styles.container}>
      <span>{value}</span>
      <span>
        {change > 0 ? (
          <p>
            +{change}
            <svg className={styles.arrowTop} viewBox="0 0 100 85">
              <polygon points="58.263,0.056 100,41.85 58.263,83.641 30.662,83.641 62.438,51.866 0,51.866 0,31.611 62.213,31.611 30.605,0 58.263,0.056" />
            </svg>
          </p>
        ) : (
          <p>
            {change}
            <svg className={styles.arrowBottom} viewBox="0 0 100 85">
              <polygon points="58.263,0.056 100,41.85 58.263,83.641 30.662,83.641 62.438,51.866 0,51.866 0,31.611 62.213,31.611 30.605,0 58.263,0.056" />
            </svg>
          </p>
        )}
      </span>
    </div>
  );
};

export const LineChart = (props: Props): JSX.Element => {
  const { yTokenName, oldPrice } = props;
  OLDPRICE = oldPrice;
  const dateShow = false;
  const size = props.data.length;
  const dataInfo = props.data;

  const primaryAxis = useMemo(
    () => ({
      show: dateShow,
      getValue: (datum: any) => datum.primary,
      formatters: {
        // eslint-disable-next-line react/no-unstable-nested-components
        tooltip: (
          value: number,
          formatters: AxisLinearOptions<TDatum>['formatters'],
        ) => <div className={styles.container}>{value}</div>,
      },
      styles: {
        tick: {
          display: 'none',
          background: 'black',
        },
        line: {
          display: 'none',
          background: 'black',
        },
      },
      // elementType: 'line',
    }),
    [],
  );

  const secondaryAxes = useMemo<AxisOptions<TDatum>[]>(
    () => [
      {
        elementType: 'line',
        getValue: (datum: any) => datum.secondary,
        formatters: {
          // eslint-disable-next-line react/no-unstable-nested-components
          tooltip: (
            value: number,
            formatters: AxisLinearOptions<TDatum>['formatters'],
          ) => <PriceValue val={value} />,
        },
      },
    ],
    [yTokenName],
  );
  // @ts-ignore TODO

  const data: UserSerie<TDatum>[] =
    // @ts-ignore TODO
    dataInfo.some((item) => item.label)
      ? dataInfo
      : [
          {
            label: 'Price',
            data: [...(dataInfo || [{ primary: '0', secondary: 0 }])],
          },
        ];

  return (
    <Box
      sx={{
        width: {
          xs: props.widthXs ?? '100%',
          sm: props.widthSm ?? '100%',
          md: props.widthMd ?? '100%',
          lg: props.widthLg ?? '100%',
          xl: props.widthXl ?? '100%',
        },
        height: props.height ?? '100%',
        minHeight: '20vh',
      }}
    >
      {size > 0 && (
        <Chart
          options={{
            data,
            primaryAxis,
            secondaryAxes,
            dark: true,
            tooltip: false,
          }}
        />
      )}
    </Box>
  );
};
