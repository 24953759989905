import webapp from '@coinweb/webapp-library';
// import { setPrivateEx } from 'cryptico';

export default function getPrivKey(strindBase64) {
  const key = webapp.create_webapp_keys();
  let { pubKey, privKey } = key; // eslint-disable-line
  const json = JSON.parse(atob(strindBase64));
  privKey.setPrivateEx(
    json.n,
    json.e,
    json.d,
    json.p,
    json.q,
    json.dmp1,
    json.dmq1,
    json.coeff,
  );
  pubKey = webapp.get_public_key(privKey);
  return {
    privKey,
    pubKey,
  };
}
